
export default class BrokerSSOValuesProvider {
    getAvailableFlows = () => {
        return [];
    }

    getAnonymousFlowMessage = () => {
        return '';
    }

    getRedirectionDetails = () => {
        return {
            baseUrlForClient: '/b-auth-policy-search',
            state: { policyNumberEditable: true }
        };
    }

    isBrokerContext = () => {
        return true;
    }

    getTypefilterNameForReporterRelation = () => {
        return 'AgentFrontEndSelectable'
    }

    saveAndResumeEnabled = () => {
        return false
    }
    
}
