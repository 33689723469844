/* eslint-disable max-len */
import _ from 'lodash';
import { buildAddressString, buildDateString, buildBankAccountDetails } from '../../pages/WizardFnolSummaryPage/CommonSummaryFunctions';
import { getTypecodeTranslation } from '../../utils/TypeCodeTranslationUtil';
import messages from './VehicleSummarySnapshotCommonFunctions.messages';
import vehiclePageMessages from '../lossDetailsComponents/LossDetailsVehicle/LossDetailsVehicle.messages';
import repairMessages from '../repairComponents/WizardFNOLAutoRepair/WizardFNOLAutoRepair.messages';
import partnerVendorMessages from '../repairComponents/PartneredVendors/PartneredVendors.messages';
import estimateMessages from '../repairComponents/estimatesComponents/EstimateComponents.messages';
import pageMessages from '../../pages/WizardFnolSummaryPage/WizardFNOLSummaryPage.messages';

export function buildVehicleDescription(wizardDTO) {
    if (!wizardDTO.value.vehicleDamage) {
        return '';
    }
    const { vehicle } = wizardDTO.value.vehicleDamage;
    let vehicleDescription = `${vehicle.make}`;

    if (vehicle.model) vehicleDescription += `, ${vehicle.model}`;
    if (vehicle.year) vehicleDescription += `, ${vehicle.year}`;
    vehicleDescription += `\n${vehicle.licensePlate}`;
    if (vehicle.vin) vehicleDescription += `, ${vehicle.vin}`;

    return vehicleDescription;
}

export function buildDriverDescription(wizardDTO, translator) {
    if (!wizardDTO.value.vehicleDamage) {
        return '';
    }
    let driverDetails = `${getTypecodeTranslation(translator, wizardDTO.value.vehicleDamage.whoWasTheDriver, 'inlb_FrontEndWhoWasDriver')}`;

    const whoWasDriver = wizardDTO.vehicleDamage.whoWasTheDriver.value.code;
    switch (whoWasDriver) {
        case 'i_was': {
            const driver = wizardDTO.reporterDetails.value;
            driverDetails += `\n${driver.firstName} ${driver.lastName} - ${buildDateString(driver.birthDate)}`;

            if (driver.emailAddress && driver.phoneNumber) {
                driverDetails += `\n${driver.emailAddress} - ${driver.phoneNumber}`;
            } else {
                if (driver.emailAddress) driverDetails += `\n${driver.emailAddress}`;
                if (driver.phoneNumber) driverDetails += `\n${driver.phoneNumber}`;
            }

            if (wizardDTO.supportiveValues.value.reporterContactFoundOnPolicy === false) {
                const { address } = driver;
                if (!_.isEmpty(address)) {
                    driverDetails += `\n${buildAddressString(address, translator)}`;
                }
                driverDetails += `\n${getTypecodeTranslation(translator, driver.gender, 'GenderType')}, ${getTypecodeTranslation(translator, driver.languageType, 'LanguageType')}, ${getTypecodeTranslation(translator, driver.relationToPolicyOwner, 'PersonRelationType')}`;
            }
            driverDetails += wizardDTO.vehicleDamage.wasTheDriverInjured.value
                ? `\n${translator(pageMessages.wizardFnolSummaryPageDriverInjured)}`
                : `\n${translator(pageMessages.wizardFnolSummaryPageDriverNotInjured)}`;

            return driverDetails;
        }
        case 'other': {
            const driver = wizardDTO.vehicleDamage.driver.value;
            driverDetails += `\n${driver.firstName} ${driver.lastName} - ${buildDateString(driver.dateOfBirth)}`;
            if (driver.emailAddress && driver.phoneNumber) {
                driverDetails += `\n${driver.emailAddress}, ${driver.phoneNumber}`;
            } else {
                if (driver.emailAddress) driverDetails += `\n${driver.emailAddress}`;
                if (driver.phoneNumber) driverDetails += `\n${driver.phoneNumber}`;
            }
            const { address } = driver;
            if (!_.isEmpty(address)) {
                driverDetails += `\n${buildAddressString(address, translator)}`;
            }

            if (!!wizardDTO.supportiveValues.showBoBQuestion.value) {
                driverDetails += `\n${translator(vehiclePageMessages.wasTheDriverABOB)}`;
                driverDetails += ' - ';
                driverDetails += wizardDTO.vehicleDamage.driver.isBoB.value
                    ? translator({ id: 'fnol.ho.views.ho-fire-details.Yes', defaultMessage: 'Yes' })
                    : translator({ id: 'fnol.ho.views.ho-fire-details.No', defaultMessage: 'No' });
            }

            if (!!wizardDTO.supportiveValues.showCarSharerQuestion.value) {
                driverDetails += `\n${translator(vehiclePageMessages.wasTheDriverACarSharer)}`;
                driverDetails += ' - ';
                driverDetails += wizardDTO.vehicleDamage.driver.isCarSharer.value
                    ? translator({ id: 'fnol.ho.views.ho-fire-details.Yes', defaultMessage: 'Yes' })
                    : translator({ id: 'fnol.ho.views.ho-fire-details.No', defaultMessage: 'No' });
            }

            driverDetails += wizardDTO.vehicleDamage.wasTheDriverInjured.value
                ? `\n${translator(pageMessages.wizardFnolSummaryPageDriverInjured)}`
                : `\n${translator(pageMessages.wizardFnolSummaryPageDriverNotInjured)}`;

            return driverDetails;
        }
        case 'no_driver': {
            return driverDetails;
        }
        default: {
            throw new Error('Unsupported driver provided');
        }
    }
}

export function buildRepairDetailsDamage(wizardDTO, translator) {
    if (_.isEmpty(wizardDTO.value.repairDetails)) {
        return '';
    }
    const repairDTOValue = wizardDTO.repairDetails.value;
    let repairDetails = '';

    switch (repairDTOValue.repairOptionChoice) {
        case 'PreferredVendor':
            repairDetails += `${translator(repairMessages.wizardFNOLAutoRepairChoicePreferredVendor)}`;
            repairDetails += `\n${repairDTOValue.repairFacilityName}`;
            repairDetails += `\n${buildAddressString(repairDTOValue.repairFacilityAddress, translator)}`;

            if (wizardDTO.supportiveValues.vendorSearchApplied.value === true) {
                repairDetails += `\n${translator(partnerVendorMessages.differentSearchCriteria)}`;
                repairDetails += `\n - ${wizardDTO.supportiveValues.vendorSearchCriteriaZipCode.value}`;
                repairDetails += `\n - ${wizardDTO.supportiveValues.vendorSearchCriteriaMake.value}`;
            }

            return repairDetails;
        case 'NewVendor': {
            repairDetails += `${translator(repairMessages.wizardFNOLAutoRepairChoiceNewVendor)}`;
            repairDetails += `${repairDTOValue.repairFacilityName}\n`;
            repairDetails += `${buildAddressString(repairDTOValue.repairFacilityAddress, translator)}`;
            return repairDetails;
        }
        case 'NoRepair': {
            repairDetails += `${translator(repairMessages.wizardFNOLAutoRepairChoiceHaventDecided)}`;
            repairDetails += `\n${translator(estimateMessages.fNOLRepairEstimatesAlreadyReceived)} - `;
            repairDetails += repairDTOValue.alreadyReceivedEstimate
                ? translator({
                    id: 'fnol.ho.views.ho-fire-details.Yes',
                    defaultMessage: 'Yes'
                })
                : translator({
                    id: 'fnol.ho.views.ho-fire-details.No',
                    defaultMessage: 'No'
                });

            if (repairDTOValue.alreadyReceivedEstimate) {
                if (!_.isEmpty(repairDTOValue.estimatedRepairCost)) repairDetails += `\n${translator(messages.wizardFnolSummaryPageRepairDetailsNoRepairEstimate)} ${repairDTOValue.estimatedRepairCost.amount} ${repairDTOValue.estimatedRepairCost.currency}`;
            }
            repairDetails += `\n${translator(estimateMessages.fNOLRepairDamageAlreadyFixed)} - ${getTypecodeTranslation(translator, repairDTOValue.damageAlreadyRepaired, 'inlb_FrontEndDmgRepaired')}`;

            if (!_.isEmpty(repairDTOValue.bankAccountDetails)) {
                repairDetails += buildBankAccountDetails(repairDTOValue.bankAccountDetails, translator);
            }

            return repairDetails;
        }
        default: throw new Error('Unsupported repair option');
    }
}
