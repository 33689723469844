/* eslint-disable max-len */
import _ from 'lodash';
// eslint-disable-next-line object-curly-newline
import React, { useState, useCallback, useContext, useRef } from 'react';
import { WizardPage, WizardPageTemplate, wizardProps } from 'gw-portals-wizard-react';
import { ViewModelForm, ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import { withRouter } from 'react-router-dom';
import { TranslatorContext } from '@jutro/locale';
import { FNOLService } from 'nn-capability-fnol';
import { useValidation } from 'gw-portals-validation-react';
import FNOLContext from '../../components/FNOLWizardContext/FNOLWizardContext';
import PartnerContext from '../../contexts/PartnerContext/PartnerContext';
import ClientContext from '../../contexts/ClientContext/ClientContext';
import { isAutoLOB, isMotorLOB, isPropertyLOB, isBikeLOB } from '../../utils/PolicyTypeUtil';
import { getProgressBasedOnWizardStep } from '../../utils/WizardStepToProgressUtil';
import styles from './WizardFNOLAdditionalInformationPage.module.scss';
import metadata from './WizardFNOLAdditionalInformationPage.metadata.json5';
import messages from './WizardFNOLAdditionalInformationPage.messages';
import DocumentUploadList from '../../components/DocumentUploadList/DocumentUploadList';
import DocumentUploadListReadOnly from '../../components/DocumentUploadList/DocumentUploadListReadOnly';
import AdditionalPartiesSection from '../../components/AdditionalPartiesSection/AdditionalPartiesSection';
import docTypeToApiLayerMapping from '../../components/DocumentUploadList/DocTypeToApiLayerMapping';
import { isLegalAssistanceOrCivilLiability } from '../../utils/LossCausesUtil';
import PleaseWait from '../../components/PleaseWait/PleaseWait';
import { maxTotalFileSizeBytes, maxTotalFilesCount } from '../../utils/Constants';
import useSnapshotHandler from '../../hooks/useSnapshotHandler';
import docOrderingConfig from './DocTypeSort.json';

function WizardAnonFNOLAdditionalInformationPage(props) {
    const translator = useContext(TranslatorContext);
    const partnerContext = useContext(PartnerContext);
    const clientContext = useContext(ClientContext);

    const {
        history,
        wizardData,
        updateWizardData,
        currentStepIndex,
        steps
    } = props;

    const {
        fnolContextState,
        setFnolContextState
    } = useContext(FNOLContext);

    const [isLoading, setIsLoading] = useState(false);

    const viewModelService = useContext(ViewModelServiceContext);

    const {
        onValidate,
        isComponentValid
    } = useValidation('WizardAnonFNOLAdditionalInformationPage');

    // eslint-disable-next-line max-len
    const [theDocuments, setTheDocuments] = useState(fnolContextState.addInfoDocuments ? fnolContextState.addInfoDocuments : []);

    const [resetValuesToSnapshotIfNeeded, prepareSnapshot] = useSnapshotHandler(steps,
        currentStepIndex, updateWizardData, 'additionalInformationSnapshot', history);

    const writeValue = useCallback((path, value) => {
        const {currentDTOValue} = resetValuesToSnapshotIfNeeded(wizardData, true, setIsLoading);
        if (currentDTOValue !== undefined) {
            _.set(currentDTOValue.value, path, value);
            updateWizardData(currentDTOValue);
        }
    }, [wizardData, updateWizardData, resetValuesToSnapshotIfNeeded, setIsLoading]);

    const getAllDocumentTypes = useCallback(() => {
        const typesDTO = viewModelService.create(
            {},
            'cc',
            'com.inlb.cc.extsys.edge.anonymousfnol.fnol.dto.TypesDTO',
        );
        const values = typesDTO.frontendDocumentType.aspects.availableValues;
        return values.map((docType) => {
            return {
                code: docType.code,
                name: translator({
                    id: docType.name,
                    defaultMessage: docType.name
                })
            };
        });
    }, [viewModelService, translator]);

    const docsOnValueChange = useCallback((newValue) => {
        setTheDocuments(newValue);

        const {fnolContextState: localFnolContextState} = resetValuesToSnapshotIfNeeded(wizardData, false, setIsLoading, true);
        if (localFnolContextState !== undefined) {
            setFnolContextState({
                ...localFnolContextState,
                addInfoDocuments: newValue
            });
        }
    }, [fnolContextState, setFnolContextState, resetValuesToSnapshotIfNeeded, setIsLoading]);

    const doTheUpload = useCallback(() => {
        const docUploadUrl = process.env.REACT_APP_DOCUMENT_UPLOAD_URL;

        if (theDocuments.length === 1 && theDocuments[0].files.length === 0) {
            return;
        }

        if (wizardData.claimNumber.value === '999999999') { // used only for testing
            wizardData.claimNumber.value = `xyz-${Math.random() * 10000}-${Math.random() * 10000}`;
        }

        const docListJSON = {
            mainAction: {
                PartnerID: '9148',
                ConversationID: wizardData.claimNumber.value,
                AttachmentsCount: 0
            },
            attachments: []
        };

        // map the documents
        theDocuments.forEach((theDoc) => {
            if (theDoc.files && theDoc.files.length > 0) {
                docListJSON.mainAction.AttachmentsCount += theDoc.files.length;
                theDoc.files.forEach((file) => {
                    const attachment = {
                        FunctionalContentType: docTypeToApiLayerMapping(theDoc.documentType),
                        File: file.name,
                        DocumentCategory: 'Claim',
                        DocumentType: 'vnd',
                        DocumentSubject: 'Claim'
                    };
                    docListJSON.attachments.push(attachment);
                });
            }
        });

        const tokenRequestDTO = {
            sessionUUID: wizardData.sessionUUID.value,
            policyNumber: wizardData.policyNumber.value,
            claimNumber: wizardData.claimNumber.value
        };

        FNOLService.getUploadDocumentToken(tokenRequestDTO, history, { partnerContext, translator }).then(
            (tokenResponseDTO) => {
                if (!tokenResponseDTO) return;
                // create form data
                const formData = new FormData();
                const requestStringifiedJSONData = JSON.stringify(docListJSON);
                let responseStringifiedJSONData;

                formData.append('jsonString', requestStringifiedJSONData);
                theDocuments.forEach((theDoc) => {
                    if (theDoc.files && theDoc.files.length > 0) {
                        docListJSON.mainAction.AttachmentsCount += theDoc.files.length;
                        theDoc.files.forEach((file) => {
                            formData.append('', file);
                        });
                    }
                });

                fetch(docUploadUrl, {
                    body: formData,
                    method: 'post',
                    headers: {
                        Authorization: `Bearer ${tokenResponseDTO.accessToken}`,
                        'Subscription-Key': process.env.REACT_APP_NN_AUTHENTICATION_SUBSCRIPTION_KEY,
                        ChannelID: '5003'
                    }
                }).then((response) => {
                    return response.json().then((resJson) => {
                        console.log(resJson);
                        return resJson;
                    });
                }).then((responseParsed) => {
                    responseStringifiedJSONData = JSON.stringify(responseParsed);
                }).catch((exception) => {
                    responseStringifiedJSONData = exception.message;
                }).finally(() => {
                    const informationInput = `Customer Document Upload. Request body: ${requestStringifiedJSONData} \n Response: ${responseStringifiedJSONData}`;
                    const informationLogDTO = viewModelService.create(
                        {
                            information: informationInput
                        },
                        'cc',
                        'com.inlb.cc.extsys.edge.anonymousfnol.fnol.dto.ClientSideInformationDTO',
                    );

                    FNOLService.logClientSideInformation(informationLogDTO.value, history, { partnerContext, translator }).then(
                        (responseDTO) => {
                            console.log(responseDTO);
                        }
                    );
                });
            }
        );
    }, [wizardData.claimNumber, wizardData.policyNumber.value, wizardData.sessionUUID.value, history, partnerContext, theDocuments, translator, viewModelService]);

    const getAllDocumentTypesSorted = useCallback(() => {
        const typelistContent = getAllDocumentTypes();
        let cfg = {};

        if (isPropertyLOB(wizardData.lob.value)) {
            const canBeSettledViaPV = partnerContext.partnerTypeAllowedForPVBroker();
            const isBrokerContext = clientContext.isBrokerContext();

            if (canBeSettledViaPV && isBrokerContext) {
                cfg = docOrderingConfig.sortOrderPropertyPVBroker;
            } else {
                cfg = isLegalAssistanceOrCivilLiability(wizardData, wizardData.supportiveValues.availableLossCauses.value)
                    ? docOrderingConfig.sortOrderPropertyCivilOrLegal
                    : docOrderingConfig.sortOrderProperty;
            }
        } else if (isBikeLOB(wizardData.lob.value)) {
            cfg = docOrderingConfig.sortOrderEBike;
        } else {
            cfg = docOrderingConfig.sortOrderVehicle;
        }

        const result = [];
        typelistContent.forEach((tc) => {
            const configRecord = cfg.find((el) => { return el.documentType === tc.code; });
            if (configRecord) result[configRecord.order - 1] = tc;
        });
        return result;
    }, [clientContext, getAllDocumentTypes, partnerContext, wizardData]);

    const addEstimateValidationsIfApplicable = useCallback((requiredDocumentTypesToMessagesMap) => {
        if (isBikeLOB(wizardData.lob.value)) {
            if (wizardData.value.frontEndLossCause === 'EBikeTheftAndTheftVandalism') {
                wizardData.value.frontEndDamageTypes.forEach((damageType) => {
                    switch (damageType) {
                        case 'EBikeTotalTheft':
                            requiredDocumentTypesToMessagesMap.push({
                                types: ['police_report', 'purchaseInvoice'],
                                validationMessage: translator(messages.ebikeTotalTheftEstimateNotProvided)
                            });
                            break;
                        case 'EBikeAttemptedTheft':
                        case 'EBikeVandalism':
                            requiredDocumentTypesToMessagesMap.push({
                                types: ['estimate', 'purchaseInvoice', 'photo', 'police_report'],
                                validationMessage: translator(messages.ebikeAttemptedTheftVandalismEstimateNotProvided)
                            });
                            break;
                        default:
                            requiredDocumentTypesToMessagesMap.push({
                                types: ['estimate', 'purchaseInvoice', 'police_report'],
                                validationMessage: translator(messages.ebikeOtherTheftEstimateNotProvided)
                            });
                            break;
                    }
                });
            } else {
                requiredDocumentTypesToMessagesMap.push({
                    types: ['estimate', 'photo', 'purchaseInvoice'],
                    validationMessage: translator(messages.ebikeOtherThanTheftEstimateNotProvided)
                });
            }
        } else {
            if (wizardData.repairDetails.value !== undefined && wizardData.repairDetails.value.alreadyReceivedEstimate) {
                requiredDocumentTypesToMessagesMap.push({
                    types: ['estimate'],
                    validationMessage: translator(messages.documentValidationEstimateNotProvided)
                });
            }
        }
    }, [translator, wizardData.lob.value, wizardData.repairDetails.value,
        wizardData.value.frontEndDamageTypes, wizardData.value.frontEndLossCause]);

    const addPVBrokerValidationsIfApplicable = useCallback((requiredDocumentTypesToMessagesMap) => {
        const repairDetails = wizardData.repairDetails.value;

        const shouldRequireBrokerPV = repairDetails !== undefined
            && repairDetails.settledViaPVProcedure === true;

        if (shouldRequireBrokerPV) {
            requiredDocumentTypesToMessagesMap.push({
                types: ['brokerPV'],
                validationMessage: translator(messages.brokerPVDocumentMissing)
            });
        }
    }, [translator, wizardData.repairDetails.value]);

    const addEuropeanAccidentStatementValidationsIfApplicable = useCallback((requiredDocumentTypesToMessagesMap) => {
        const additionalParties = wizardData.value.additionalParties;

        const shouldRequireEuroAccStatement = !_.isEmpty(additionalParties)
            && (isMotorLOB(wizardData.lob.value) || isAutoLOB(wizardData.lob.value))
            && additionalParties.find((party) => party.canIdentifyParty === 'yesWithEAS');

        if (shouldRequireEuroAccStatement) {
            requiredDocumentTypesToMessagesMap.push({
                types: ['euro_acc_statement'],
                validationMessage: translator(messages.europeanAccidentStatementMissing)
            });
        }
    }, [translator, wizardData.lob.value, wizardData.value.additionalParties]);

    const getRequiredDocumentsAndValidationMessages = useCallback(() => {
        const requiredDocumentTypesToMessagesMap = [];

        addEstimateValidationsIfApplicable(requiredDocumentTypesToMessagesMap);
        addPVBrokerValidationsIfApplicable(requiredDocumentTypesToMessagesMap);
        addEuropeanAccidentStatementValidationsIfApplicable(requiredDocumentTypesToMessagesMap);

        return requiredDocumentTypesToMessagesMap;
    }, [addEstimateValidationsIfApplicable, addEuropeanAccidentStatementValidationsIfApplicable, addPVBrokerValidationsIfApplicable]);

    const onResetUploadedDocuments = useCallback(() => {
        writeValue('documentAttachments', [])
    }, [writeValue])

    const isSaveAndResumeEnabled = useCallback(() => {
        return clientContext.saveAndResumeEnabled()
    }, [clientContext])

    let defaultDocType = '';
    if (isAutoLOB((wizardData.lob.value))) defaultDocType = 'euro_acc_statement';


    const filesWithTypes = theDocuments.filter((doc) => !_.isEmpty(doc.files));

    const overrides = {
        documentUploadList: {
            value: theDocuments,
            onValueChange: docsOnValueChange,
            availableDocumentTypes: getAllDocumentTypesSorted(),
            maxTotalFilesSizeBytes: maxTotalFileSizeBytes(),
            maxTotalFilesCount: maxTotalFilesCount(),
            defaultDocumentType: defaultDocType,
            requiredDocumentTypesToMessagesMap: getRequiredDocumentsAndValidationMessages(),
            visible: !_.isEmpty(filesWithTypes) || _.isEmpty(wizardData.value.documentAttachments),
            isSaveAndResumeEnabled: isSaveAndResumeEnabled()
        },
        documentUploadListReadOnly: {
            value: wizardData.value.documentAttachments,
            visible: !_.isEmpty(wizardData.value.documentAttachments) && _.isEmpty(filesWithTypes),
            availableDocumentTypes: getAllDocumentTypesSorted(),
            onResetUploadedDocuments: onResetUploadedDocuments
        },
        fnolAdditionalParties: {
            value: wizardData
        },
        pleaseWait: {
            value: isLoading
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            writeValue,
            onValidate,
        },
        resolveComponentMap: {
            documentList: DocumentUploadList,
            documentListReadOnly: DocumentUploadListReadOnly,
            additionalPartiesList: AdditionalPartiesSection,
            pleaseWait: PleaseWait
        }
    };

    const handleOnNextClick = useCallback(() => {
        setIsLoading(true);
        const claimData = _.cloneDeep(wizardData.value);
        claimData.progress = getProgressBasedOnWizardStep(steps[currentStepIndex + 1].path);
        claimData.supportiveValues.attachmentsUploadConversationID = claimData.claimNumber;

        let docAtt;
        const filteredDocuments = theDocuments.filter((doc) => doc.documentType !== undefined && !_.isEmpty(doc.files));
        if (!_.isEmpty(filteredDocuments)) {
            docAtt = filteredDocuments.map((doc) => {
                const documentDTO = {
                    documentType: doc.documentType
                }


                if (!_.isEmpty(doc.files)) {
                    const fileObjects = doc.files.map((fileDTO) => ({fileName: fileDTO.name, size: fileDTO.size}))
                    documentDTO.files = fileObjects;
                }
                return documentDTO
            })
        };
        
        claimData.documentAttachments = docAtt;
        const claimSaveResponse = FNOLService.updateClaim(claimData, history, { partnerContext, translator }).then(
            (responseDTO) => {
                if (!responseDTO) return;
                wizardData.value = responseDTO;
                prepareSnapshot(wizardData.value);
                return wizardData;
            }
        ).finally(() => {
        });
        doTheUpload();
        setIsLoading(false);
        return claimSaveResponse;
    }, [wizardData, currentStepIndex, doTheUpload, history, partnerContext, prepareSnapshot, steps, translator]);

    const generateHeader = () => {
        return (
            <div>
                <h1>
                    {translator(messages.wizardFnolAdditionalInformationPageTitle)}
                </h1>
                <h1>
                    {wizardData.value.claimNumber}
                </h1>
            </div>
        );
    };

    return (
        <WizardPage
            template={WizardPageTemplate}
            renderContextComponent={generateHeader}
            disableNext={!isComponentValid}
            onNext={handleOnNextClick}
            showCancel={false}
        >
            <ViewModelForm
                model={wizardData}
                uiProps={metadata.pageContent}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
                onValueChange={writeValue}
                overrideProps={overrides}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

WizardAnonFNOLAdditionalInformationPage.propTypes = wizardProps;
export default withRouter(WizardAnonFNOLAdditionalInformationPage);
