import _ from 'lodash';
import { getTypecodeTranslation } from '../../utils/TypeCodeTranslationUtil';
import { buildDateString, buildAddressString, buildBankAccountDetails } from '../../pages/WizardFnolSummaryPage/CommonSummaryFunctions';
import { isEBikeTotalOrAttemptedTheft, isEBikeVandalismOrTotalAttemptedTheft } from '../../utils/DamageTypesUtil';
import messages from './WizardFNOLSummaryEBike.messages';
import basicInformationMessages from '../../pages/WizardFnolBasicInformationPage/WizardFNOLBasicInformationPage.messages';
import bikeLossDetailsMessages from '../lossDetailsComponents/LossDetailsBike/LossDetailsBike.messages';
import bikeRepairMessages from '../repairComponents/WizardFNOLEBikeRepair/WizardFNOLEBikeRepair.messages';
import summaryPageMessages from '../../pages/WizardFnolSummaryPage/WizardFNOLSummaryPage.messages';

export function buildWhenDidItHappen(wizardDTO, translator) {
    const typecodeTranslation = getTypecodeTranslation(translator, wizardDTO.value.whenDidItHappen, 'inlb_WhenDidItHappen');
    if (wizardDTO.value.whenDidItHappen !== 'Other') {
        return typecodeTranslation;
    }

    return `${typecodeTranslation} - ${wizardDTO.value.whenDidItHappenOther}`;
}

export function buildEBikeDescription(wizardDTO) {
    const bikeDamageDetails = wizardDTO.value.bikeDetailsDamages;
    if (!bikeDamageDetails) {
        return '';
    }

    let bikeDescription = `${bikeDamageDetails.make}`;
    bikeDescription += `, ${bikeDamageDetails.year}`;

    return bikeDescription;
}

export function buildEBikeTheftDetails(wizardDTO, translator) {
    if (!isEBikeVandalismOrTotalAttemptedTheft(wizardDTO.value.frontEndDamageTypes)) return '';

    const bikeDamageDetails = wizardDTO.value.bikeDetailsDamages;
    if (!bikeDamageDetails) {
        return '';
    }

    let theftString = '';
    theftString += `${getTypecodeTranslation(translator, bikeDamageDetails.bikeLocation, 'inlb_VehicleLocation')}\n`;
    switch (bikeDamageDetails.bikeLocation) {
        case 'privateRoom':
            theftString += bikeDamageDetails.wasRoomLocked
                ? `${translator(messages.eBikeRoomWasLocked)}\n`
                : `${translator(messages.eBikeRoomWasNotLocked)}\n`;
            break;
        case 'enclosedCommonArea':
            theftString += `${bikeDamageDetails.locationOther}\n`;
            break;
        case 'publicAccessibleSpace':
            theftString += `${bikeDamageDetails.locationOther}\n`;
            break;
        default:
            break;
    }

    theftString += `${translator(messages.eBikePlacedThereSince)} ${buildDateString(bikeDamageDetails.sinceWhenWasBikeStoredThere)}`;
    return theftString;
}

export function buildEBikeSecurityMeasuresDetails(wizardDTO, translator) {
    if (!isEBikeTotalOrAttemptedTheft(wizardDTO.value.frontEndDamageTypes)) return '';

    const bikeDamageDetails = wizardDTO.value.bikeDetailsDamages;
    if (!bikeDamageDetails) {
        return '';
    }

    const yesLabel = translator({
        id: 'fnol.ho.views.ho-fire-details.Yes',
        defaultMessage: 'Yes'
    });

    const noLabel = translator({
        id: 'fnol.ho.views.ho-fire-details.No',
        defaultMessage: 'No'
    });

    let securityMeasuresString = '';
    let requiredLockString = `${bikeDamageDetails.requiredLockUsed ? yesLabel : noLabel}`;
    if (bikeDamageDetails.requiredLockUsed === false) requiredLockString += ` - ${bikeDamageDetails.requiredLockUsedExplanation}`;

    securityMeasuresString += `${translator(bikeLossDetailsMessages.securityMeasures_RequiredLockUsed)} - ${requiredLockString}\n`;
    securityMeasuresString += `${translator(bikeLossDetailsMessages.securityMeasures_DoYouHaveBothLockKeys)} - ${bikeDamageDetails.doYouHaveBothKeysOfTheLock ? yesLabel : noLabel}\n`;
    securityMeasuresString += `${translator(bikeLossDetailsMessages.securityMeasures_WasAFixedPointsUsed)} - ${bikeDamageDetails.wasAFixedPointUsed ? yesLabel : noLabel}\n`;
    securityMeasuresString += `${translator(bikeLossDetailsMessages.securityMeasures_ForseenAGPSTracker)} - ${bikeDamageDetails.forseenAGPSTracker ? yesLabel : noLabel}\n`;

    return securityMeasuresString;
}

export function buildCyclistDescription(wizardDTO, translator) {
    const bikeDamageDetails = wizardDTO.value.bikeDetailsDamages;
    if (!bikeDamageDetails) {
        return '';
    }

    let cyclistDetails = `${getTypecodeTranslation(translator, bikeDamageDetails.whoWasTheCyclist, 'inlb_FrontEndWhoWasDriver')}`;

    const whoWasDriver = bikeDamageDetails.whoWasTheCyclist;
    switch (whoWasDriver) {
        case 'i_was_cyclist': {
            const driver = wizardDTO.reporterDetails.value;
            cyclistDetails += `\n${driver.firstName} ${driver.lastName} - ${buildDateString(driver.birthDate)}\n${driver.emailAddress} - ${driver.phoneNumber}`;

            if (wizardDTO.supportiveValues.value.reporterContactFoundOnPolicy === false) {
                const { address } = driver;
                cyclistDetails += `\n${buildAddressString(address, translator)}`;
                cyclistDetails += `\n${getTypecodeTranslation(translator, driver.gender, 'GenderType')}, ${getTypecodeTranslation(translator, driver.languageType, 'LanguageType')}, ${getTypecodeTranslation(translator, driver.relationToPolicyOwner, 'PersonRelationType')}`;
            }
            cyclistDetails += wizardDTO.bikeDetailsDamages.wasCyclistInjured.value
                ? `\n${translator(summaryPageMessages.wizardFnolSummaryPageDriverInjured)}`
                : `\n${translator(summaryPageMessages.wizardFnolSummaryPageDriverNotInjured)}`;

            return cyclistDetails;
        }
        case 'other_cyclist': {
            const driver = wizardDTO.bikeDetailsDamages.cyclist.value;
            cyclistDetails += `\n${driver.firstName} ${driver.lastName} - ${buildDateString(driver.dateOfBirth)}`;
            return cyclistDetails;
        }
        case 'no_cyclist': {
            return cyclistDetails;
        }
        default: {
            throw new Error('Unsupported driver provided');
        }
    }
}

export function buildEBikeDamages(wizardDTO, translator) {
    const bikeDamageDetails = wizardDTO.value.bikeDetailsDamages;
    if (!bikeDamageDetails) {
        return '';
    }

    let damageString = '';

    if (bikeDamageDetails.frontWheelDamaged) damageString += `${translator(bikeLossDetailsMessages.frontWheelDamaged)}\n`;
    if (bikeDamageDetails.backWheelDamaged) damageString += `${translator(bikeLossDetailsMessages.backWheelDamaged)}\n`;
    if (bikeDamageDetails.handlebarsDamaged) damageString += `${translator(bikeLossDetailsMessages.handelbarsDamaged)}\n`;
    if (bikeDamageDetails.chainDamaged) damageString += `${translator(bikeLossDetailsMessages.chainDamaged)}\n`;
    if (bikeDamageDetails.frameDamaged) damageString += `${translator(bikeLossDetailsMessages.frameDamaged)}\n`;
    if (bikeDamageDetails.brakesDamaged) damageString += `${translator(bikeLossDetailsMessages.brakesDamaged)}\n`;
    if (bikeDamageDetails.pedalsDamaged) damageString += `${translator(bikeLossDetailsMessages.pedalsDamaged)}\n`;
    if (bikeDamageDetails.totalLossTheftDamage) damageString += `${translator(bikeLossDetailsMessages.totalLossDamaged)}\n`;
    if (bikeDamageDetails.otherPartDamaged) damageString += `${translator(bikeLossDetailsMessages.otherPartsDamaged)} - ${bikeDamageDetails.otherPartDamagedText}\n`;
    if (bikeDamageDetails.noVisibleDamages) damageString += `${translator(bikeLossDetailsMessages.noVisibleDamage)}\n`;

    return damageString;
}

export function buildOtherEBikeDamage(wizardDTO, translator) {
    const bikeDamageDetails = wizardDTO.value.bikeDetailsDamages;
    if (!bikeDamageDetails) {
        return '';
    }

    let damageString = '';
    if (bikeDamageDetails.otherDamagesBikeBag) damageString += `${translator(bikeLossDetailsMessages.otherDamagesBikeBag)}\n`;
    if (bikeDamageDetails.otherDamagesBikeLock) damageString += `${translator(bikeLossDetailsMessages.otherDamagesBikeLock)}\n`;
    if (bikeDamageDetails.otherDamagesBasket) damageString += `${translator(bikeLossDetailsMessages.otherDamagesBasker)}\n`;
    if (bikeDamageDetails.otherDamagesBikeTrailer) damageString += `${translator(bikeLossDetailsMessages.otherDamagesBikeTrailer)}\n`;
    if (bikeDamageDetails.otherDamagesChildSeat) damageString += `${translator(bikeLossDetailsMessages.otherDamagesChildSeat)}\n`;
    if (bikeDamageDetails.otherDamagesGPS) damageString += `${translator(bikeLossDetailsMessages.otherDamagesGPS)}\n`;
    if (bikeDamageDetails.otherDamagesOther) damageString += `${translator(bikeLossDetailsMessages.otherDamagesOtherDamage)} - ${bikeDamageDetails.otherDamagesOtherText}\n`;

    return damageString;
}

export function buildRepairDetailsDamage(wizardDTO, translator) {
    if (_.isEmpty(wizardDTO.value.repairDetails)) {
        return '';
    }
    const repairDTOValue = wizardDTO.repairDetails.value;
    let repairDetails = '';

    if (!_.isEmpty(repairDTOValue.estimatedRepairCost)) repairDetails += `${translator(bikeRepairMessages.ebikeEstimationBike)} ${repairDTOValue.estimatedRepairCost.amount} ${repairDTOValue.estimatedRepairCost.currency}\n`;
    if (!_.isEmpty(repairDTOValue.estimatedOtherRepairCosts)) repairDetails += `${translator(bikeRepairMessages.ebikeEstimationOtherCosts)} ${repairDTOValue.estimatedOtherRepairCosts.amount} ${repairDTOValue.estimatedOtherRepairCosts.currency}\n`;

    if (!_.isEmpty(repairDTOValue.bankAccountDetails)) {
        repairDetails += buildBankAccountDetails(repairDTOValue.bankAccountDetails, translator);
    }

    return repairDetails;
}

export function generateEBikePartSnapshot(translator, wizardDTO) {
    const autoEbikeSnapshot = [];

    autoEbikeSnapshot.push({
        label: translator(basicInformationMessages.wizardFNOLBasicInformationWhenDidItHappen),
        value: buildWhenDidItHappen(wizardDTO, translator)
    });

    autoEbikeSnapshot.push({
        label: translator(bikeLossDetailsMessages.aboutTheBike),
        value: buildEBikeDescription(wizardDTO)
    });

    if (isEBikeVandalismOrTotalAttemptedTheft(wizardDTO.value.frontEndDamageTypes)) {
        autoEbikeSnapshot.push({
            label: translator(bikeLossDetailsMessages.aboutTheBikeTheft),
            value: buildEBikeTheftDetails(wizardDTO, translator)
        });
    }

    if (isEBikeTotalOrAttemptedTheft(wizardDTO.value.frontEndDamageTypes)) {
        autoEbikeSnapshot.push({
            label: translator(bikeLossDetailsMessages.aboutTheSecurityMeasures),
            value: buildEBikeSecurityMeasuresDetails(wizardDTO, translator)
        });
    }

    autoEbikeSnapshot.push({
        label: translator(bikeLossDetailsMessages.aboutTheInvolvedCyclist),
        value: buildCyclistDescription(wizardDTO, translator)
    });

    if (!_.isEmpty(buildEBikeDamages(wizardDTO, translator))) {
        autoEbikeSnapshot.push({
            label: translator(bikeLossDetailsMessages.aboutTheDamagedBikeTitle),
            value: buildEBikeDamages(wizardDTO, translator)
        });
    }

    if (!_.isEmpty(buildOtherEBikeDamage(wizardDTO, translator))) {
        autoEbikeSnapshot.push({
            label: translator(bikeLossDetailsMessages.aboutTheOtherDamagesBikeTitle),
            value: buildOtherEBikeDamage(wizardDTO, translator)
        });
    }

    autoEbikeSnapshot.push({
        label: translator(bikeRepairMessages.ebikeEstimationInformation),
        value: buildRepairDetailsDamage(wizardDTO, translator)
    });

    return autoEbikeSnapshot;
}
