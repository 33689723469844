import { defineMessages } from 'react-intl';

export default defineMessages({
    fnolSearchPolicyWarningHeader: {
        id: 'anonymous.fnol.components.search-policy.Sorry, provided data is not valid, please provide correct data.',
        defaultMessage: 'Sorry, the provided data are not valid, please provide correct data.'
    },
    fnolSearchPolicyHeader: {
        id: 'anonymous.fnol.components.search-policy.Please provide info about your insurance',
        defaultMessage: 'Please provide info about your insurance'
    },
    fnolSearchPolicyInput: {
        id: 'anonymous.fnol.components.search-policy.Policy Number',
        defaultMessage: 'Your policy number'
    },
    fnolSearchPolicyInputTooltip: {
        id: 'anonymous.fnol.components.search-policy.You can find the policy number on the 1st page of your policy contract. Please provide policy number without any separators (e.g. dashes or any other signs)',
        defaultMessage: 'You can find the policy number on the first page of your policy contract, on your international insurance card (for a car claim) or in your insurance/bank application. Please provide policy number without any separators (e.g. dashes or any other signs)'
    },
    fnolSearchPolicyLossDate: {
        id: 'anonymous.fnol.components.search-policy.Date of loss',
        defaultMessage: 'Date of loss'
    },
    fnolSearchPolicyLossDateTooltip: {
        id: 'anonymous.fnol.components.search-policy.Please provide the exact day the loss happened.',
        defaultMessage: 'Please provide the exact date the loss happened. If you do not know, enter the date on which you noticed the damage.'
    },
    fnolSearchPolicyLossDateInFuture: {
        id: 'anonymous.fnol.components.search-policy.Date of loss can not be in the future. Please provide correct date',
        defaultMessage: 'Date of loss cannot be in the future. Please provide a correct date'
    },
    fnolSearchPolicySearchButton: {
        id: 'anonymous.fnol.components.search-policy.Register a claim',
        defaultMessage: 'Let\'s get started!'
    }
});
