/* eslint-disable max-len */
import { JsonRPCService } from 'gw-portals-transport-js';
import { getProxiedServiceUrl } from 'gw-portals-url-js';
import nnCustomMessages from '../../../capabilities-react/nn-capability-fnol-react/nnCustomMessages';

const unsupportedExceptionCodes = [101, 404, 502, 504];

export default class FNOLService {
    static wrapCallWithErrorHandling(handlerName, methodName, parameters, history, contexts, additionalHeaders = {}) {
        const { partnerContext, translator } = contexts;
        const response = JsonRPCService
            .send(getProxiedServiceUrl(handlerName), methodName, parameters, additionalHeaders)
            .then((responseValue) => {
                return new Promise((resolve, reject) => {
                    resolve(responseValue);
                });
            }).catch((error) => {
                if (unsupportedExceptionCodes.indexOf(error.appErrorCode) !== -1) {
                    if (error.appErrorCode !== 101 || (error.appErrorCode === 101 && !error.baseError)) {
                        history.push({
                            pathname: '/contact-call-center',
                            state: {
                                redirectPath: '/',
                                notificationTitle: translator(nnCustomMessages.systemUnderMaintananceTitle),
                                notificationBody: translator(nnCustomMessages.systemUnderMaintananceBody, { shortPartnerLabel: partnerContext.partnerShortLabelWithPhoneNumber(translator) }),
                                showTryAgainButton: false,
                                showPhoneCall: false,
                                allowSaveAndResumeMessage: true
                            }
                        });
                        return;
                    }

                    if (error.baseError) {
                        history.push({
                            pathname: '/contact-call-center',
                            state: {
                                redirectPath: '/',
                                allowSaveAndResumeMessage: true
                            }
                        });
                        return;
                    }
                }
                return new Promise((resolve, reject) => {
                    reject(error);
                });
            });
        return response;
    }

    static initializeClaim(searchCriteria, captchaToken, history, contexts, additionalHeaders = {}) {
        return this.wrapCallWithErrorHandling('anonymous', 'initializeClaim', [searchCriteria, captchaToken], history, contexts, additionalHeaders);
    }

    static updateClaim(searchCriteria, history, contexts, additionalHeaders = {}) {
        return this.wrapCallWithErrorHandling('anonymous', 'updateClaim', [searchCriteria], history, contexts, additionalHeaders);
    }

    static finalizeClaim(data, history, contexts, additionalHeaders = {}) {
        return this.wrapCallWithErrorHandling('anonymous', 'finalizeClaim', [data], history, contexts, additionalHeaders);
    }

    static getAvailableFrontEndClaimCauses(searchCriteria, history, contexts, additionalHeaders = {}) {
        return this.wrapCallWithErrorHandling('anonymous', 'getAvailableFrontEndClaimCauses', [searchCriteria], history, contexts, additionalHeaders);
    }

    static handleFrontEndBasicInformationInput(searchCriteria, history, contexts, additionalHeaders = {}) {
        return this.wrapCallWithErrorHandling('anonymous', 'handleFrontEndBasicInformationInput', [searchCriteria], history, contexts, additionalHeaders);
    }

    static searchDriverOnPolicy(searchCriteria, history, contexts, additionalHeaders = {}) {
        return this.wrapCallWithErrorHandling('anonymous', 'searchDriverOnPolicy', [searchCriteria], history, contexts, additionalHeaders);
    }

    static validateBankAccount(searchCriteria, history, contexts, additionalHeaders = {}) {
        return this.wrapCallWithErrorHandling('anonymous', 'validateBankAccount', [searchCriteria], history, contexts, additionalHeaders);
    }

    static getVendorsBasedOnPolicy(searchCriteria, history, contexts, additionalHeaders = {}) {
        return this.wrapCallWithErrorHandling('anonymous', 'getVendorsBasedOnPolicy', [searchCriteria], history, contexts, additionalHeaders);
    }

    static processQuickFlowRequest(searchCriteria, history, contexts, additionalHeaders = {}) {
        return this.wrapCallWithErrorHandling('anonymous', 'processQuickFlowRequest', [searchCriteria], history, contexts, additionalHeaders);
    }

    static vendorSearch(searchCriteria, history, contexts, additionalHeaders = {}) {
        return this.wrapCallWithErrorHandling('anonymous', 'vendorSearch', [searchCriteria], history, contexts, additionalHeaders);
    }

    static getUploadDocumentToken(data, history, contexts, additionalHeaders = {}) {
        return this.wrapCallWithErrorHandling('anonymous', 'getUploadDocumentToken', [data], history, contexts, additionalHeaders);
    }

    static getUserPolicies(data, history, contexts, additionalHeaders = {}) {
        return this.wrapCallWithErrorHandling('authenticatedce', 'getUserPolicies', [data], history, contexts, additionalHeaders);
    }

    static initializeAuthenticatedUserFlow(data, history, contexts, additionalHeaders = {}) {
        return this.wrapCallWithErrorHandling('authenticatedce', 'initializeAuthenticatedUserFlow', [data], history, contexts, additionalHeaders);
    }

    static retrieveServiceToken(history, contexts, additionalHeaders = {}) {
        return this.wrapCallWithErrorHandling('authenticatedce', 'retrieveServiceToken', [], history, contexts, additionalHeaders);
    }

    static logClientError(data, history, contexts, additionalHeaders = {}) {
        return this.wrapCallWithErrorHandling('anonymous', 'logClientError', [data], history, contexts, additionalHeaders);
    }

    static logClientSideInformation(data, history, contexts, additionalHeaders = {}) {
        return this.wrapCallWithErrorHandling('anonymous', 'logClientSideInformation', [data], history, contexts, additionalHeaders);
    }

    static initializeAuthenticatedBrokerFlow(searchCriteria, authenticationToken, history, contexts, additionalHeaders = {}) {
        return this.wrapCallWithErrorHandling('authenticatedce', 'initializeAuthenticatedBrokerFlow', [searchCriteria, authenticationToken], history, contexts, additionalHeaders);
    }

    static retrieveDraftClaimInformation(data, history, contexts, additionalHeaders = {}) {
        return this.wrapCallWithErrorHandling('authenticatedce', 'retrieveDraftClaimInformation', [data], history, contexts, additionalHeaders);
    }
}
