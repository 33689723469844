import { useCallback, useState } from 'react';
import { FNOLService } from 'nn-capability-fnol';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { withViewModelService } from 'gw-portals-viewmodel-react';
import metadata from './SearchPolicyPage.metadata.json5';
import styles from './SearchPolicyPage.module.scss';
import SearchPolicy from '../../components/SearchPolicy/SearchPolicy';
import useErrorHandler from '../../hooks/useErrorHandler';

function FNOLSearchPolicyPage(props) {
    const { history } = props;
    const errorHandler = useErrorHandler();
    const [timeStampVisibleValue, setTimeStampVisibleValue] = useState();

    const startProcess = useCallback((data, captchaToken, hist, contexts) => (
        FNOLService.initializeClaim(data, captchaToken, hist, contexts).then(
            (fnolDto) => {
                if (!fnolDto) return;

                return history.push({
                    pathname: '/fnol',
                    state: {
                        redirectPath: '/',
                        fnolDto
                    }
                });
            }
        )
    ), [history]);

    const handleError = useCallback((error) => {
        errorHandler.handleError(error);
        return false;
    }, [errorHandler]);

    const redirectToSubFlow = useCallback((data) => {
        return history.push({
            pathname: '/quick-flow',
            state: {
                redirectPath: '/',
                initialData: data,
                timeStampVisibleValue: timeStampVisibleValue
            }
        });
    }, [history, timeStampVisibleValue]);

    const overrideProps = {
        searchpolicy: {
            startProcess: startProcess,
            handleError: handleError,
            redirectToSubFlow: redirectToSubFlow,
            history: history,
            timeStampVisibleValue: timeStampVisibleValue,
            setTimeStampVisibleValue: setTimeStampVisibleValue
        }
    };

    const resolvers = {
        resolveComponentMap: {
            searchpolicy: SearchPolicy
        },
        resolveCallbackMap: {
            startProcess,
            handleError,
            redirectToSubFlow
        },
        resolveClassNameMap: styles
    };

    return renderContentFromMetadata(
        metadata.pageContent,
        overrideProps,
        resolvers
    );
}

export default withViewModelService(FNOLSearchPolicyPage);
