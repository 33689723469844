import { ITS_ME_FLOW_CODE } from '../ClientServiceConstants';
import messages from './PrivateCustomerValuesProvider.messages';

export default class PrivateCustomerValuesProvider {
    getAvailableFlows = () => {
        return [ITS_ME_FLOW_CODE];
    }

    getAnonymousFlowMessage = () => {
        return messages.privateCustomerAnonymousFlowMessage;
    }

    getRedirectionDetails = () => {
        return {
            baseUrlForClient: '/',
            state: {}
        };
    }

    isBrokerContext = () => {
        return false;
    }

    getTypefilterNameForReporterRelation = () => {
        return 'FrontEndSelectable'
    }

    saveAndResumeEnabled = () => {
        return true
    }
    
}
