import AgencyPortalValuesProvider from './ap/AgencyPortalValuesProvider';
import BrokerSSOValuesProvider from './bsso/BrokerSSOValuesProvider';
import PrivateCustomerValuesProvider from './pc/PrivateCustomerValuesProvider';

export default function getClientValuesProvider(clientCode) {
    switch (clientCode) {
        case 'AP':
            return new AgencyPortalValuesProvider();
        case 'BSSO':
            return new BrokerSSOValuesProvider();
        default:
            return new PrivateCustomerValuesProvider();
    }
}
