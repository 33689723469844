import { useContext } from 'react';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { TranslatorContext } from '@jutro/locale';
import PartnerContext from '../../contexts/PartnerContext/PartnerContext';
import ClientContext from '../../contexts/ClientContext/ClientContext';
import { ANONYMOUS_FLOW_CODE } from '../../services/client/ClientServiceConstants';
import metadata from './AuthenticatedContactCallCenterPage.metadata.json5';
import styles from './AuthenticatedContactCallCenterPage.scss';
// eslint-disable-next-line no-unused-vars
import messages from './AuthenticatedContactCallCenterPage.messages';

function AuthenticatedContactCallCenterPage(props) {
    const parnerContext = useContext(PartnerContext);
    const clientContext = useContext(ClientContext);
    const translator = useContext(TranslatorContext);
    const phoneNumber = parnerContext.partnerPhoneNumber(translator);
    const clientAvailableFlows = clientContext.getAvailableFlows();
    const {
        history
    } = props;

    const performCall = () => {
        window.location.href = `tel:${phoneNumber}`;
    };

    const redirectToAnonymousFnol = () => {
        return history.push('/policy-search');
    };

    const overrideProps = {
        authFnolContactCallCenterWithoutMeDiv: {
            visible: clientAvailableFlows.indexOf(ANONYMOUS_FLOW_CODE) !== -1
        },
        authFnolContactCallCenterPageUrgentCallButton: {
            content: phoneNumber
        },
        authFnolContactCallCenterUrgentHelp: {
            content: parnerContext.partnerUrgentAssistLabel(translator)
        }
    };

    const resolvers = {
        resolveComponentMap: {},
        resolveCallbackMap: {
            performCall: performCall,
            redirectToAnonymousFnol: redirectToAnonymousFnol
        },
        resolveClassNameMap: styles
    };

    return renderContentFromMetadata(
        metadata.pageContent,
        overrideProps,
        resolvers
    );
}

export default AuthenticatedContactCallCenterPage;
