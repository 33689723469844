/* eslint-disable max-len */
import _ from 'lodash';
import React, { useCallback, useContext, useEffect } from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { useValidation } from 'gw-portals-validation-react';
import { FNOLService } from 'nn-capability-fnol';
import { TranslatorContext } from '@jutro/locale';
import { isOtherDamageSelected } from '../../utils/DamageTypesUtil';
import BasicInformationQuestionSet from '../BasicInformationQuestionSet/BasicInformationQuestionSet';
import PartnerContext from '../../contexts/PartnerContext/PartnerContext';
import metadata from './BasicInformationDamageTypesAndQuestionSets.metadata.json5';
import styles from './BasicInformationDamageTypesAndQuestionSets.module.scss';
// eslint-disable-next-line no-unused-vars
import messages from './BasicInformationDamageTypesAndQuestioSets.messages';

function BasicInformationDamageTypesAndQuestionSets(props) {
    const translator = useContext(TranslatorContext);
    const partnerContext = useContext(PartnerContext);
    const {
        id,
        history,
        onValidate,
        value: claimVM,
        onWriteValue,
        onCreateBasicInfoDTO: createBasicInfoDTO,
        onSetIsLoading: setIsLoading
    } = props;

    const {
        onValidate: onComponentValidate,
        isComponentValid
    } = useValidation('BasicInformationDamageTypesAndQuestionSets');

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    useEffect(() => {
        const value = claimVM.value.frontEndDamageTypes;
        if (!value) {
            return;
        }
        setIsLoading(true);
        const dto = createBasicInfoDTO({ pickedFrontEndDamageTypes: value });

        let isCancelled = false;
        FNOLService.handleFrontEndBasicInformationInput(dto.value, history, { partnerContext, translator }).then(
            (responseDTO) => {
                if (isCancelled || !responseDTO) return;
                if (!_.isEqual(responseDTO.availableQuestionSets, claimVM.supportiveValues.value.availableQuestionSets)) {
                    onWriteValue(responseDTO.availableQuestionSets, 'supportiveValues.availableQuestionSets');
                }
            }
        ).finally(() => {
            if (isCancelled) return;
            setIsLoading(false);
        });
        // eslint-disable-next-line consistent-return
        return () => {
            isCancelled = true;
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [claimVM.value.frontEndDamageTypes]);

    const onQuestionSetPick = useCallback((value, damageType, questionSet) => {
        let questionSets = claimVM.value.pickedQuestionSets;
        const isSingleSelect = claimVM.value.supportiveValues.availableQuestionSets.filter((quesSet) => quesSet.damageType === damageType)[0].singleSelect;
        if (value) {
            if (!questionSets) {
                questionSets = [];
            }

            const questionSetsForDamageType = questionSets.filter((set) => set.damageType === damageType);

            if (questionSetsForDamageType.length === 0) {
                const setForDamageType = {
                    damageType: damageType,
                    availableQuestions: [questionSet],
                    haveChanged: true
                };
                questionSets.push(setForDamageType);
            } else {
                const questionDamageSet = questionSetsForDamageType[0];
                questionDamageSet.haveChanged = true;
                if (isSingleSelect) {
                    questionDamageSet.availableQuestions = [questionSet];
                } else {
                    questionDamageSet.availableQuestions.push(questionSet);
                }
            }
        } else {
            const correspondingDamageType = questionSets.filter((set) => set.damageType === damageType)[0];
            const newQuestionSets = correspondingDamageType.availableQuestions.filter((set) => set !== questionSet);
            if (_.isEmpty(newQuestionSets)) {
                questionSets = questionSets.filter((set) => set.damageType !== damageType);
            } else {
                correspondingDamageType.availableQuestions = newQuestionSets;
                correspondingDamageType.haveChanged = true;
            }
        }

        onWriteValue(questionSets, 'pickedQuestionSets');
    }, [claimVM.value.pickedQuestionSets, claimVM.value.supportiveValues.availableQuestionSets, onWriteValue]);

    const onGetQuestionSetValue = useCallback((damageType, questionSet) => {
        const questionSets = claimVM.value.pickedQuestionSets;

        if (!questionSets) {
            return false;
        }

        const questionSetsForDamageType = questionSets.filter((set) => set.damageType === damageType);

        if (questionSetsForDamageType.length === 0) {
            return false;
        }

        return questionSetsForDamageType[0].availableQuestions.filter((question) => question === questionSet).length === 1;
    }, [claimVM.value.pickedQuestionSets]);

    const onShowOtherDamagesInput = useCallback((damageType) => {
        const questionSets = claimVM.value.pickedQuestionSets;

        if (!questionSets) {
            return false;
        }

        const questionSetsForDamageType = questionSets.filter((set) => set.damageType === damageType);

        if (questionSetsForDamageType.length === 0) {
            return false;
        }

        return !_.isEmpty(questionSetsForDamageType[0].availableQuestions.filter((set) => set === 'otherqs'));
    }, [claimVM.value.pickedQuestionSets]);

    const onGetQuestionSetOtherDamage = useCallback((damageType) => {
        const questionSets = claimVM.value.pickedQuestionSets;

        if (!questionSets) {
            return false;
        }

        const questionSetsForDamageType = questionSets.filter((set) => set.damageType === damageType);

        if (questionSetsForDamageType.length === 0) {
            return '';
        }

        return questionSetsForDamageType[0].otherDescription;
    }, [claimVM.value.pickedQuestionSets]);

    const onQuestionSetOtherDamageUpdate = useCallback((damageType, newValue) => {
        const questionSets = claimVM.value.pickedQuestionSets;
        const questionSetForDamageType = questionSets.filter((set) => set.damageType === damageType);
        questionSetForDamageType[0].otherDescription = newValue;
        onWriteValue(questionSets, 'pickedQuestionSets');
    }, [claimVM.value.pickedQuestionSets, onWriteValue]);

    useEffect(() => {
        const availableQuestionSets = claimVM.value.supportiveValues.availableQuestionSets;
        if (_.isEmpty(availableQuestionSets)) {
            if (!_.isEmpty(claimVM.value.pickedQuestionSets)) {
                onWriteValue([], 'pickedQuestionSets');
            }
            return;
        }

        const questionSets = [];

        availableQuestionSets.forEach((questionSet) => {
            const correspondingPickedQuestionSet = claimVM.value.pickedQuestionSets.filter((pqs) => pqs.damageType === questionSet.damageType)[0];
            if (correspondingPickedQuestionSet !== undefined) {
                questionSets.push(correspondingPickedQuestionSet);
            } else if (questionSet.availableQuestions.length === 1) {
                questionSets.push({
                    damageType: questionSet.damageType,
                    availableQuestions: [questionSet.availableQuestions[0]]
                });
            }
        });
        
        if (!_.isEqual(questionSets, claimVM.value.pickedQuestionSets)) {
            onWriteValue(questionSets, 'pickedQuestionSets');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [claimVM.value.supportiveValues.availableQuestionSets]);

    const getAvailableDamageTypesAndQuestions = useCallback(() => {
        return claimVM.value.supportiveValues.availableDamageTypes.map((damageType) => {
            if (_.isEmpty(claimVM.value.supportiveValues.availableQuestionSets)) {
                return { damageType: damageType };
            }
            const correspondingQuestionSets = claimVM.value.supportiveValues.availableQuestionSets.filter((questionSet) => questionSet.damageType === damageType);
            if (correspondingQuestionSets.length !== 0) {
                return correspondingQuestionSets[0];
            }
            return { damageType: damageType };
        });
    }, [claimVM.value.supportiveValues.availableDamageTypes, claimVM.value.supportiveValues.availableQuestionSets]);

    const onDamageTypeSelection = useCallback((value, damageType, singleSelect) => {
        let damageTypesToBeWritten;
        if (singleSelect) {
            damageTypesToBeWritten = [damageType];
        } else {
            const oldDamageTypes = claimVM.value.frontEndDamageTypes || [];
            if (value) {
                damageTypesToBeWritten = [...oldDamageTypes, damageType];
            } else {
                damageTypesToBeWritten = oldDamageTypes.filter((damage) => damage !== damageType);
            }
        }
        onWriteValue(damageTypesToBeWritten, 'frontEndDamageTypes')
    }, [claimVM.value.frontEndDamageTypes, onWriteValue]);

    const onIsDamageTypeSelected = useCallback((damageType) => {
        if (claimVM.value.frontEndDamageTypes) return !_.isEmpty(claimVM.value.frontEndDamageTypes.filter((damage) => damage === damageType));
        return false;
    }, [claimVM.value.frontEndDamageTypes]);

    const onGetMultipleDamageTypesAllowed = useCallback(() => {
        return claimVM.value.supportiveValues.allowMultipleDamageTypesToBeSelected;
    }, [claimVM.value.supportiveValues.allowMultipleDamageTypesToBeSelected]);

    const overrideProps = {
        wizardFNOLBasicInformationDamageTypeOtherDescription: {
            visible: isOtherDamageSelected(claimVM.frontEndDamageTypes)
        },
        damageTypesQuestionSets: {
            wizardDTO: claimVM,
            data: getAvailableDamageTypesAndQuestions(),
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            writeValue: onWriteValue,
            onValidate,
            onQuestionSetPick,
            onGetQuestionSetValue,
            onQuestionSetOtherDamageUpdate,
            onGetQuestionSetOtherDamage,
            onShowOtherDamagesInput,
            onDamageTypeSelection,
            onIsDamageTypeSelected,
            onGetMultipleDamageTypesAllowed
        },
        resolveComponentMap: {
            questionSet: BasicInformationQuestionSet
        }
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={claimVM}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onComponentValidate}
                onValueChange={onWriteValue}
            />
        </div>
    );
}

export default BasicInformationDamageTypesAndQuestionSets;
