/* eslint-disable max-len */
import _ from 'lodash';
import React, { useEffect, useCallback, useContext } from 'react';
import { ViewModelForm, ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import { useValidation } from 'gw-portals-validation-react';
import { TranslatorContext } from '@jutro/locale';
import { FNOLService } from 'nn-capability-fnol';
import {
    Button,
    InputField,
    ModalNextProvider
} from '@jutro/components';
import FNOLContext from '../../FNOLWizardContext/FNOLWizardContext';
import PartnerContext from '../../../contexts/PartnerContext/PartnerContext';
import metadata from './PartneredVendors.metadata.json5';
import styles from './PartneredVendors.module.scss';
import messages from './PartneredVendors.messages';

function PartneredVendors(props) {
    const translator = useContext(TranslatorContext);
    const partnerContext = useContext(PartnerContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const {
        id,
        history,
        repairDTO,
        onValidate,
        writeValue,
        writeValueDirectly,
        wizardDTO,
        setIsLoading,
        resetValuesToSnapshotIfNeeded
    } = props;

    const {
        onValidate: onComponentValidate,
        isComponentValid
    } = useValidation('PartneredVendors');

    const {
        fnolContextState,
        setFnolContextState
    } = useContext(FNOLContext);

    useEffect(() => {
        if (!_.isEmpty(fnolContextState.availablePartnerFacilities) || repairDTO.value.repairFacilityName !== undefined) return;

        const lookForVendorsDTO = viewModelService.create(
            {
                sessionUUID: wizardDTO.sessionUUID.value,
                policyNumber: wizardDTO.policyNumber.value,
                claimNumber: wizardDTO.claimNumber.value,
                postalCode: wizardDTO.supportiveValues.vendorSearchCriteriaZipCode.value,
                make: wizardDTO.supportiveValues.vendorSearchCriteriaMake.value,
                repairFacilityName: wizardDTO.supportiveValues.vendorSearchCriteriaName.value
            },
            'cc',
            'com.inlb.cc.extsys.edge.anonymousfnol.fnol.dto.vendorSearch.VendorsBasedOnPolicyReqDTO'
        );

        FNOLService.getVendorsBasedOnPolicy(lookForVendorsDTO.value, history, { partnerContext, translator }).then(
            (partneredVendorsRespDTO) => {
                if (!partneredVendorsRespDTO) return;

                const {fnolContextState: localFnolContextState} = resetValuesToSnapshotIfNeeded(wizardDTO, false, setIsLoading, true);
                if (localFnolContextState !== undefined) {
                    setFnolContextState({
                        ...localFnolContextState,
                        availablePartnerFacilities: partneredVendorsRespDTO.partnerFacilities
                    });
                }
            }
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const mapTableData = useCallback(() => {
        if (_.isEmpty(fnolContextState.availablePartnerFacilities)) {
            return [];
        }

        return fnolContextState.availablePartnerFacilities.map((vendor) => {
            return {
                name: vendor.name,
                brandedGarage: vendor.brandedGarage,
                distance: vendor.distance,
                phoneNumber: vendor.phoneNumber,
                emailAddress: vendor.emailAddress,
                street: vendor.addressLine,
                city: vendor.city,
                postalCode: vendor.postalCode,
                country: vendor.country
            };
        });
    }, [fnolContextState.availablePartnerFacilities]);

    const selectVendorFacility = useCallback((data) => {
        writeValue({
            country: data.country,
            city: data.city,
            postalCode: data.postalCode,
            street: data.street
        }, 'repairFacilityAddress');

        writeValue(data.name, 'repairFacilityName');
        writeValue(data.emailAddress, 'repairFacilityEmailAddress');
        writeValue(data.phoneNumber, 'repairFacilityPhoneNumber');
    }, [writeValue]);

    const buildAddressString = useCallback((data) => {
        let addressString = '';
        if (data.street) addressString += `${data.street}`;
        if (data.postalCode) addressString += `, ${data.postalCode}`;
        if (data.city) addressString += ` ${data.city}`;
        if (data.country) addressString += `, ${data.country}`;

        return addressString;
    }, []);

    const expanderContent = (data) => {
        return (
            <div className={styles.flexContainer}>
                <Button onClick={() => selectVendorFacility(data)} type="secondary" className={styles.rightMargin}>
                    {translator(messages.selectFacilityButton)}
                </Button>
                <div>
                    <InputField
                        id="facilityAddress"
                        className={styles.repairerDetailsContent}
                        label={translator(messages.facilityAddress)}
                        labelPosition="left"
                        readOnly
                        value={buildAddressString(data)}
                    />
                    <InputField
                        id="emailAddressInput"
                        className={styles.repairerDetailsContent}
                        label={translator(messages.emailAddress)}
                        labelPosition="left"
                        readOnly
                        value={data.emailAddress}
                    />
                </div>
            </div>
        );
    };

    const getDataTableCell = (item, index, { path: property }) => {
        return item[property];
    };

    const handleSearchRepairers = useCallback(() => {
        const lookForVendorsDTO = viewModelService.create(
            {
                sessionUUID: wizardDTO.sessionUUID.value,
                policyNumber: wizardDTO.policyNumber.value,
                claimNumber: wizardDTO.claimNumber.value,
                postalCode: wizardDTO.supportiveValues.vendorSearchCriteriaZipCode.value,
                make: wizardDTO.supportiveValues.vendorSearchCriteriaMake.value,
                repairFacilityName: wizardDTO.supportiveValues.vendorSearchCriteriaName.value
            },
            'cc',
            'com.inlb.cc.extsys.edge.anonymousfnol.fnol.dto.vendorSearch.VendorsBasedOnPolicyReqDTO'
        );

        FNOLService.getVendorsBasedOnPolicy(lookForVendorsDTO.value, history, { partnerContext, translator }).then(
            (partneredVendorsRespDTO) => {
                if (!partneredVendorsRespDTO) return;
                if (_.isEmpty(partneredVendorsRespDTO.partnerFacilities)) {
                    ModalNextProvider.showAlert({
                        title: translator(messages.differentSearchCriteriaVendorsNotFoundTitle),
                        message: translator(messages.differentSearchCriteriaVendorsNotFoundSubTitle),
                        status: 'error',
                        icon: 'mi-error-outline',
                        confirmButtonText: translator(messages.differentSearchCriteriaVendorsNotFoundOkButton)
                    }).catch(_.noop);
                } else {
                    const {fnolContextState: localFnolContextState} = resetValuesToSnapshotIfNeeded(wizardDTO, false, setIsLoading)
                    if (localFnolContextState !== undefined) {
                        setFnolContextState({
                            ...localFnolContextState,
                            availablePartnerFacilities: partneredVendorsRespDTO.partnerFacilities
                        });
                    }
                }
            }
        );
    }, [viewModelService, wizardDTO.sessionUUID.value, wizardDTO.policyNumber.value,
        wizardDTO.claimNumber.value, wizardDTO.supportiveValues.vendorSearchCriteriaZipCode,
        wizardDTO.supportiveValues.vendorSearchCriteriaMake, wizardDTO.supportiveValues.vendorSearchCriteriaName,
        history, partnerContext, translator, resetValuesToSnapshotIfNeeded]);

    const overrideProps = {
        TestDataTable: {
            data: mapTableData(),
            getDataTableCell: getDataTableCell,
            renderExpanderContent: expanderContent,
        },
        fnolSelectedFacilityDetails: {
            visible: !!repairDTO.value.repairFacilityName
        },
        fnolRecommendedFacilitySelectedAddress: {
            value: buildAddressString(repairDTO.value.repairFacilityAddress)
        },
        differentSearchCriteriaFacilityContainer: {
            visible: !!wizardDTO.supportiveValues.vendorSearchApplied.value
        },
        differentSearchCriteria: {
            onValueChange: writeValueDirectly,
            value: wizardDTO.supportiveValues.vendorSearchApplied.value
        },
        differentSearchCriteriaFacilityName: {
            visible: false, // Current integration is not able to handle that field
            value: wizardDTO.supportiveValues.vendorSearchCriteriaName.value,
            onValueChange: writeValueDirectly
        },
        differentSearchCriteriaFacilityZipCode: {
            value: wizardDTO.supportiveValues.vendorSearchCriteriaZipCode.value,
            onValueChange: writeValueDirectly
        },
        differentSearchCriteriaFacilityMake: {
            onValueChange: writeValueDirectly,
            value: wizardDTO.supportiveValues.vendorSearchCriteriaMake.value,

        },
        differentSearchCriteriaFacilityButton: {
            disabled: !(wizardDTO.supportiveValues.vendorSearchCriteriaZipCode.value
                && wizardDTO.supportiveValues.vendorSearchCriteriaMake.value)
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            getDataTableCell,
            onRowClick: (data) => {
                _.set(data, 'expanded', !data.expanded);
            },
            handleSearchRepairers
        }
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={repairDTO}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                onValidationChange={onComponentValidate}
                onValueChange={writeValue}
            />
        </div>
    );
}

export default PartneredVendors;
