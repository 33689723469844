/* eslint-disable max-len */
import React, { useState, useContext, useEffect, useCallback, useRef } from 'react';
import { ViewModelForm, ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import { useValidation } from 'gw-portals-validation-react';
import { ServiceManager } from '@jutro/services';
import { TranslatorContext } from '@jutro/locale';
import PropTypes from 'prop-types';
import moment from 'moment';
import PartnerContext from '../../contexts/PartnerContext/PartnerContext';
import { redirectToContactCallCenter } from '../../utils/RedirectionUtil';
import nnCustomMessages from '../../nnCustomMessages';
import metadata from './SearchPolicy.metadata.json5';
import styles from './SearchPolicy.module.scss';
// eslint-disable-next-line no-unused-vars
import messages from './SearchPolicy.messages';

function FNOLSearchPolicy(props) {
    const {
        startProcess,
        handleError,
        redirectToSubFlow,
        history,
        timeStampVisibleValue,
        setTimeStampVisibleValue
    } = props;

    const translator = useContext(TranslatorContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const partnerContext = useContext(PartnerContext);
    const localeService = ServiceManager.getService('locale-service');

    const {
        onValidate,
        isComponentValid,
        registerComponentValidation
    } = useValidation('SearchPolicy');

    const [policySearchDTO, setPolicySearchDTO] = useState({});
    const [noPolicyFoundCounter, setNoPolicyFoundCounter] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const captchaRef = useRef();
    const recaptchaEnabled = process.env.REACT_APP_RECAPTCHA_ENABLED;
    const recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;
    const dateOfBirthEnabled = process.env.REACT_APP_POLICY_SEARCH_DATE_OF_BIRTH_REQUIRED;
    const [recaptchaSolved, setRecaptchaSolved] = useState(false);

    useEffect(() => {
        const initialDataObject = history.location.state && history.location.state.initialData
            ? history.location.state.initialData
            : { policyNumber: '' };

        const initialSearchDTO = viewModelService.create(
            initialDataObject,
            'cc',
            'com.inlb.cc.extsys.edge.anonymousfnol.policy.dto.PolicySearchDTO'
        );

        return setPolicySearchDTO(initialSearchDTO);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Empty Array as we don't want this code to re-run

    const validateTimeOfLoss = useCallback(() => {
        if (policySearchDTO && policySearchDTO.value) {
            if (timeStampVisibleValue === undefined) return false;

            return timeStampVisibleValue
                ? policySearchDTO.value.lossDate.hour !== undefined
                : true;
        }
        return true;
    }, [policySearchDTO, timeStampVisibleValue]);

    useEffect(() => {
        registerComponentValidation(validateTimeOfLoss);
    }, [registerComponentValidation, validateTimeOfLoss]);

    const onValueChange = useCallback((value, path) => {
        const updatedDTO = viewModelService.clone(policySearchDTO);
        _.set(updatedDTO.value, path, value);
        setPolicySearchDTO(updatedDTO);
    }, [policySearchDTO, viewModelService]);

    const handleRegisterClaim = useCallback(() => {
        setIsLoading(true);
        const recaptchaToken = recaptchaEnabled === 'true'
            ? captchaRef.current.getValue()
            : '';
        const contexts = { partnerContext, translator };

        startProcess(policySearchDTO.value, recaptchaToken, history, contexts).catch((error) => {
            switch (error.appErrorCode) {
                case 603:
                    if (noPolicyFoundCounter >= 3) {
                        redirectToSubFlow(policySearchDTO.value);
                    } else {
                        setNoPolicyFoundCounter((currentValue) => currentValue + 1);
                    }
                    break;
                case 620:
                    redirectToContactCallCenter(history,
                        translator(nnCustomMessages.fnolContactCallCenterFromLandingRedirectionErrorTitle),
                        translator(nnCustomMessages.fnolContactCallCenterFromLandingRedirectionErrorSubTitle),
                        false, false);
                    break;
                default:
                    handleError();
            }
        }).finally(() => {
            setIsLoading(false);
        });
    }, [recaptchaEnabled, partnerContext, translator, startProcess,
        policySearchDTO.value, history, noPolicyFoundCounter, handleError, redirectToSubFlow]);

    const handleReCaptchaChange = useCallback((value) => {
        setRecaptchaSolved(value != null);
    }, [setRecaptchaSolved]);

    const handleReCaptchaExpired = useCallback(() => {
        setRecaptchaSolved(false);
    }, [setRecaptchaSolved]);

    const overrideProps = {
        fnolSearchPolicyLossDate: {
            maxDate: moment().toDate(),
            timeStampVisibleValue: timeStampVisibleValue,
            setTimeStampVisibleValue: setTimeStampVisibleValue
        },
        fnolSearchPolicySearchButton: {
            disabled: !isComponentValid
                || (recaptchaEnabled === 'true' && !recaptchaSolved)
        },
        fnolSearchPolicyDateOfBirthDiv: {
            visible: dateOfBirthEnabled === 'true'
        },
        fnolPolicySearchLossDateTime: {
            lossDateDTO: policySearchDTO.lossDate
        },
        fnolSearchPolicyWarningHeader: {
            visible: noPolicyFoundCounter >= 1
        },
        fnolSearchPolicyMainContainer: {
            visible: !isLoading
        },
        fnolSearchPolicyPageLoader: {
            visible: isLoading
        },
        fnolSearchPolicyRecaptchaDiv: {
            visible: recaptchaEnabled === 'true'
        },
        fnolReCaptcha: {
            ref: captchaRef,
            sitekey: recaptchaKey,
            hl: localeService.getStoredLanguage(),
            onChange: handleReCaptchaChange,
            onExpired: handleReCaptchaExpired
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            handleRegisterClaim,
            onValueChange
        }
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={policySearchDTO}
                overrideProps={overrideProps}
                onValidationChange={onValidate}
                onValueChange={onValueChange}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </div>
    );
}

FNOLSearchPolicy.propTypes = {
    startProcess: PropTypes.func.isRequired
};

export default FNOLSearchPolicy;
