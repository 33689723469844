/* eslint-disable no-restricted-globals */
/* eslint-disable max-len */
import _ from 'lodash';
import React, { useState, useContext, useEffect, useCallback } from 'react';
import { setComponentMapOverrides } from '@jutro/uiconfig';
import { TranslatorContext } from '@jutro/locale';
import { DependencyProvider } from 'gw-portals-dependency-react';
import { ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import { ViewModelServiceFactory } from 'gw-portals-viewmodel-js';
import vmTranslator, { messages as commonMessages } from 'gw-platform-translations';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';
import { AccurateBreakpointPropagation } from 'gw-jutro-adapters-react';
import { FNOLService } from 'nn-capability-fnol';
import { Main, ModalNextProvider } from '@jutro/components';
import {
    PrivacyPolicyPage,
    AssuraliaAntiFraudPage,
    FNOLSearchPolicyPage,
    FNOLWizard,
    ContactCallCenterPage,
    FNOLQuickFlowPage,
    PageContainer,
    FnolConfirmationPage,
    AuthenticationLandingPage,
    AuthenticatedPolicySelect,
    AuthenticatedBrokerPolicySearch,
    AnonymousLOBSelectionPage,
    AuthenticatedContactCallCenterPage,
    platformComponents as nnPlatformComponents,
    platformComponentMap as nnPlatformComponentMap
} from 'nn-capability-fnol-react';
import './App.scss';
import useErrorHandler from 'nn-capability-fnol-react/hooks/useErrorHandler';
import { ErrorBoundary } from 'gw-portals-error-react';
import { WizardPageTemplateWithTitle } from 'gw-portals-wizard-react';

import {
    platformComponentMap,
    platformComponents,
    ImageComponent,
} from 'gw-components-platform-react';

import AuthenticatedContext from '../../../../common/capabilities-react/nn-capability-fnol-react/contexts/AuthenticatedContext/AuthenticatedContext';
import PartnerContext from '../../../../common/capabilities-react/nn-capability-fnol-react/contexts/PartnerContext/PartnerContext';
import getPartnerValuesProvider from '../../../../common/capabilities-react/nn-capability-fnol-react/services/partner/PartnerServiceLayer';
import ClientContext from '../../../../common/capabilities-react/nn-capability-fnol-react/contexts/ClientContext/ClientContext';
import getClientValuesProvider from '../../../../common/capabilities-react/nn-capability-fnol-react/services/client/ClientServiceLayer';

import messages from './App.messages';

setComponentMapOverrides(
    {
        ...platformComponentMap,
        ...nnPlatformComponentMap,
        img: { component: 'img' },
        WizardPageTemplateWithTitle: { component: 'WizardPageTemplateWithTitle' },
    },
    {
        ...platformComponents,
        ...nnPlatformComponents,
        img: ImageComponent,
        WizardPageTemplateWithTitle,
    }
);

function App() {
    const translator = useContext(TranslatorContext);
    const [viewModelService, setViewModelService] = useState(undefined);
    const channel = sessionStorage.getItem('channel');
    const client = sessionStorage.getItem('client');
    const errorHandler = useErrorHandler();

    const buildInitialAuthenticationData = useCallback(() => {
        const authData = {};
        const urlParams = new URL(window.location.href).searchParams;
        if (urlParams.has('redirect')) {
            authData.token = urlParams.get('redirect');
        }

        if (urlParams.has('token')) {
            authData.bAuthToken = urlParams.get('token');
        }

        if (urlParams.has('policyNumber')) {
            authData.policyNumber = urlParams.get('policyNumber');
        }

        if (urlParams.has('error')) {
            authData.error = urlParams.get('error');
        }

        return authData;
    }, []);

    const buildInitialPartnerData = useCallback(() => {
        return getPartnerValuesProvider(channel.toUpperCase());
    }, [channel]);

    const [authenticationData, setAuthenticationData] = useState(buildInitialAuthenticationData());
    const [parnerValuesProvider] = useState(buildInitialPartnerData());

    const buildInitialClientData = useCallback(() => {
        return getClientValuesProvider(client.toUpperCase());
    }, [client]);

    const [clientValuesProvider] = useState(buildInitialClientData());

    function popStateListener(event) {
        const allowedSearchPaths = ['/b-auth-policy-search', '/policy-search', '/policy-select']
        const foundPath = allowedSearchPaths.find((path) => {
            if (location.href.includes(path)) {
                return true;
            }
        })

        if (foundPath !== undefined) {
            ModalNextProvider.showAlert({
                title: messages.titleNotification,
                message: messages.subtitleNotification,
                status: 'error',
                icon: 'mi-error-outline',
                confirmButtonText: messages.confirmNotification
            }).catch(_.noop);
            history.pushState(null, null, location.href.replace(foundPath, '/fnol'));
        }
    }

    window.addEventListener('popstate', popStateListener);

    useEffect(() => {
        const translatorFn = vmTranslator(translator);
        import(
            /* webpackChunkName: "product-metadata" */
            // eslint-disable-next-line import/no-unresolved
            'product-metadata'
        ).then((productMetadata) => {
            const { default: result } = productMetadata;
            setViewModelService(
                ViewModelServiceFactory.getViewModelService(result, translatorFn)
            );
        });
    }, [translator]);

    const handleError = useCallback((error) => {
        errorHandler.handleError(error);
        return false;
    }, [errorHandler]);

    const buildInitialPath = () => {
        // As URLS start from /INF/EN/AP which is not recognized by application as valid paths in application context, application by default redirects every entry to main page "/".
        // What we're trying to do here is to make sure, that a exact page can be specified and entered even if "/INF/EN/AP" is present.
        const requestedURL = location.href;
        const allowedPaths = ['/b-auth-policy-search', '/policy-search', '/policy-select']
    
        const foundPath = allowedPaths.find((path) => {
            if (requestedURL.includes(path)) {
                return path;
            }
        })
    
        return foundPath || '/';
    };
    
    const validatedInitialPath = buildInitialPath();

    return (
        <AccurateBreakpointPropagation>
            <DependencyProvider value={{
                FNOLService
            }}
            >
                <ErrorBoundary onError={handleError}>
                    <AuthenticatedContext.Provider value={{ authenticationData, setAuthenticationData }}>
                        <PartnerContext.Provider value={parnerValuesProvider}>
                            <ClientContext.Provider value={clientValuesProvider}>
                                <ViewModelServiceContext.Provider value={viewModelService}>
                                    <ModalNextProvider />
                                    <Router>
                                        <Main className="fnolMain" contentClassName="fnolMainContent" fluid>
                                            {client !== 'CP' && (
                                                <PageContainer>
                                                    <Switch>
                                                        <Route exact path="/" component={AuthenticationLandingPage} />
                                                        <Route exact path="/anonymous-lob-page" component={AnonymousLOBSelectionPage} />
                                                        <Route exact path="/policy-search" component={FNOLSearchPolicyPage} />
                                                        <Route exact path="/policy-select" component={AuthenticatedPolicySelect} />
                                                        <Route exact path="/b-auth-policy-search" component={AuthenticatedBrokerPolicySearch} />
                                                        <Route exact path="/auth-call-center" component={AuthenticatedContactCallCenterPage} />
                                                        <Route exact path="/privacy-policy" component={PrivacyPolicyPage} />
                                                        <Route exact path="/assuralia-anti-fraud" component={AssuraliaAntiFraudPage} />
                                                        <Route path="/fnol" component={FNOLWizard} />
                                                        <Route exact path="/confirmation-page" component={FnolConfirmationPage} />
                                                        <Route exact path="/contact-call-center" component={ContactCallCenterPage} />
                                                        <Route path="/quick-flow" component={FNOLQuickFlowPage} />
                                                        <Route render={() => <Redirect to={validatedInitialPath} />} />
                                                    </Switch>
                                                </PageContainer>
                                            )}
                                            {client === 'CP' && (
                                                <Switch>
                                                    <Route exact path="/" component={AuthenticationLandingPage} />
                                                    <Route exact path="/anonymous-lob-page" component={AnonymousLOBSelectionPage} />
                                                    <Route exact path="/policy-search" component={FNOLSearchPolicyPage} />
                                                    <Route exact path="/policy-select" component={AuthenticatedPolicySelect} />
                                                    <Route exact path="/b-auth-policy-search" component={AuthenticatedBrokerPolicySearch} />
                                                    <Route exact path="/auth-call-center" component={AuthenticatedContactCallCenterPage} />
                                                    <Route exact path="/privacy-policy" component={PrivacyPolicyPage} />
                                                    <Route exact path="/assuralia-anti-fraud" component={AssuraliaAntiFraudPage} />
                                                    <Route path="/fnol" component={FNOLWizard} />
                                                    <Route exact path="/confirmation-page" component={FnolConfirmationPage} />
                                                    <Route exact path="/contact-call-center" component={ContactCallCenterPage} />
                                                    <Route path="/quick-flow" component={FNOLQuickFlowPage} />
                                                    <Route render={() => <Redirect to={validatedInitialPath} />} />
                                                </Switch>
                                            )}
                                        </Main>
                                    </Router>
                                </ViewModelServiceContext.Provider>
                            </ClientContext.Provider>
                        </PartnerContext.Provider>
                    </AuthenticatedContext.Provider>
                </ErrorBoundary>
            </DependencyProvider>
        </AccurateBreakpointPropagation>
    );
}

export default App;
