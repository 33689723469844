/* eslint-disable max-len */
import _ from 'lodash';
import React, { useMemo, useCallback, useState, useContext, useEffect } from 'react';
import { wizardProps } from 'gw-portals-wizard-react';
import { ViewModelForm, ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import { withRouter } from 'react-router-dom';
import { useValidation } from 'gw-portals-validation-react';
import { FNOLService } from 'nn-capability-fnol';
import moment from 'moment';
import { isAutoLOB, isPropertyLOB, isBikeLOB } from '../../utils/PolicyTypeUtil';
import FNOLAddress from '../Address/Address';
import PartnerContext from '../../contexts/PartnerContext/PartnerContext';
import ClientContext from '../../contexts/ClientContext/ClientContext';
import useBreakpointHandler from '../../hooks/useBreakpointHandler';
import styles from './WizardFNOLContactDetailsComponent.module.scss';
import BankAccountNumberComponent from '../BankAccountNumberComponent/BankAccountNumberComponent';
import { isLegalAssistanceAuto } from '../../utils/DamageTypesUtil';
import { isDamageTypeOther, isLegalAssistanceProperty } from '../../utils/LossCausesUtil';
import metadata from './WizardFNOLContactDetailsComponent.metadata.json5';
import AuthenticatedContext from '../../contexts/AuthenticatedContext/AuthenticatedContext';

function WizardFNOLContactDetailsComponent(props) {
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useContext(TranslatorContext);
    const partnerContext = useContext(PartnerContext);
    const clientContext = useContext(ClientContext);

    const {
        id,
        history,
        wizardData,
        currentStepIndex,
        steps,
        onValidate,
        writeValue
    } = props;

    const [
        isMobileDevice
    ] = useBreakpointHandler();

    const [driverIsLoading, setDriverIsLoading] = useState(false);

    const {
        onValidate: onComponentValidate,
        isComponentValid,
        registerComponentValidation,
        disregardFieldValidation
    } = useValidation('ContactDetailsComponent');

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const componentValidation = useCallback(() => {
        const reporterBankDetails = wizardData.reporterDetails.bankAccountDetails.value;

        if (_.isEmpty(reporterBankDetails) || reporterBankDetails.bankAccountNumber === undefined || _.isEmpty(reporterBankDetails.bankAccountNumber)) {
            return true
        }

        return reporterBankDetails.isValid === true;
    }, [wizardData.reporterDetails.bankAccountDetails]);

    useEffect(() => {
        registerComponentValidation(componentValidation);
    }, [registerComponentValidation, componentValidation]);

    const {
        authenticationData
    } = useContext(AuthenticatedContext);

    if (!wizardData.value.reporterDetails) {
        const data = {
            address: {}
        };
        writeValue(data, 'reporterDetails');
    }

    useEffect(() => {
        if (!_.isEmpty(authenticationData) && !steps[currentStepIndex + 1].visited) {
            writeValue(true, 'reporterDetails.haveChanged');
        }
    }, []);

    useEffect(() => {
        if (isPropertyLOB(wizardData.lob.value)) {
            wizardData.reporterDetails.wasInjured.value = false;
        }
    }, [wizardData.lob.value, wizardData.reporterDetails]);

    const availableLanguages = useMemo(() => {
        const availableValues = wizardData.reporterDetails.languageType.aspects
            .availableValues[0].typelist.filters
            .filter((filter) => filter.name === 'FrontEndSelectable')[0].codes;

        return availableValues.map((typecode) => {
            return {
                code: typecode.code,
                name: {
                    id: typecode.name,
                    defaultMessage: typecode.name
                }
            };
        });
    }, [wizardData]);

    const availableRelationsToPolicyOwner = useMemo(() => {
        const typefilterName = clientContext.getTypefilterNameForReporterRelation()
        const availableValues = wizardData.reporterDetails.relationToPolicyOwner.aspects
            .availableValues[0].typelist.filters
            .filter((filter) => filter.name === typefilterName)[0].codes;

        return availableValues.map((typecode) => {
            return {
                code: typecode.code,
                name: {
                    id: typecode.name,
                    defaultMessage: typecode.name
                }
            };
        });
    }, [wizardData]);

    const resetOptionalValues = useCallback((shouldReset) => {
        // Prevent resetting initial data in authenticated flow
        if (shouldReset !== true) return;
        if (wizardData.value.reporterDetails.address.city) {
            writeValue({}, 'reporterDetails.address');
        }

        if (wizardData.value.reporterDetails.gender) {
            writeValue(null, 'reporterDetails.gender');
        }

        if (wizardData.value.reporterDetails.languageType) {
            writeValue(null, 'reporterDetails.languageType');
        }

        if (wizardData.value.reporterDetails.relationToPolicyOwner) {
            writeValue(null, 'reporterDetails.relationToPolicyOwner');
        }
    }, []);

    const shouldAreYouInjuredBeVisible = useCallback(() => {
        if (isPropertyLOB(wizardData.lob.value)) return false;

        if (isBikeLOB(wizardData.lob.value)) {
            const whoWasCyclist = _.get(wizardData, 'bikeDetailsDamages.whoWasTheCyclist');
            return whoWasCyclist
                ? whoWasCyclist.value.code !== 'i_was_cyclist'
                : true;
        }

        if (isLegalAssistanceAuto(wizardData.frontEndDamageTypes)) return false;
        const whoWasDriver = _.get(wizardData, 'vehicleDamage.whoWasTheDriver');
        return whoWasDriver
            ? whoWasDriver.value.code !== 'i_was'
            : true;
    }, [wizardData]);

    const queryOtherDriver = (firstName, lastName, dateOfBirth) => {
        if (!firstName || !lastName || !dateOfBirth) {
            writeValue(false, 'supportiveValues.reporterContactFoundOnPolicy')
            return;
        }
        setDriverIsLoading(true);
        const driverOnPolicyQuestionDTO = viewModelService.create(
            {
                sessionUUID: wizardData.sessionUUID.value,
                policyNumber: wizardData.policyNumber.value,
                claimNumber: wizardData.claimNumber.value,
                dateOfBirth: dateOfBirth,
                firstName: firstName,
                lastName: lastName
            },
            'cc',
            'com.inlb.cc.extsys.edge.anonymousfnol.fnol.dto.DriverOnPolicyExistsQuestionDTO'
        );

        FNOLService.searchDriverOnPolicy(driverOnPolicyQuestionDTO.value, history, { partnerContext, translator }).then(
            (searchDriverAnswerDTO) => {
                if (!searchDriverAnswerDTO || searchDriverAnswerDTO.driverFound === wizardData.supportiveValues.reporterContactFoundOnPolicy.value) return;
                writeValue(searchDriverAnswerDTO.driverFound, 'supportiveValues.reporterContactFoundOnPolicy')
            }
        ).finally(() => {
            setDriverIsLoading(false);
        });
    };

    useEffect(() => {
        if (steps[currentStepIndex + 1].visited) return;
        resetOptionalValues(steps[currentStepIndex + 1].visited);
        if (wizardData.supportiveValues.value.reporterContactFoundOnPolicy) {
            disregardFieldValidation(['fnolContactDetailsAddress']);
        }
    }, [disregardFieldValidation,
        steps,
        currentStepIndex,
        resetOptionalValues]);

    const queryOtherDriverDebounced = useCallback(_.debounce(queryOtherDriver, 800), []);

    useEffect(() => {
        if (!wizardData.reporterDetails.firstName.value
            || !wizardData.reporterDetails.lastName.value
            || !wizardData.reporterDetails.birthDate.value) {
            return;
        }
        setDriverIsLoading(true);
        queryOtherDriverDebounced(wizardData.reporterDetails.firstName.value,
            wizardData.reporterDetails.lastName.value,
            wizardData.reporterDetails.birthDate.value);
    }, [wizardData.reporterDetails.firstName.value,
        wizardData.reporterDetails.lastName.value,
        wizardData.reporterDetails.birthDate.value,
        queryOtherDriverDebounced]);

    const styleIfNoMobile = (style) => {
        return isMobileDevice() ? null : style;
    };

    const firstLastNameStyleDependantOfDevice = () => {
        return isMobileDevice() ? 'phoneFirstLastName' : 'firstLastName';
    };

    const halfWidthStyleDependantOfDevice = () => {
        return isMobileDevice() ? 'phoneHalfWidthWithMargin' : 'halfWidthWithMargin';
    };

    const shouldShowAccountNumberSection = useCallback(() => {
        if (!wizardData.value.authenticatedFlowDetails) {
            return false;
        }

        if (isLegalAssistanceAuto(wizardData.frontEndDamageTypes)) {
            return wizardData.supportiveValues.value.reporterContactFoundOnPolicy === true;
        }

        if (isDamageTypeOther(wizardData, wizardData.supportiveValues.value.availableLossCauses)
            || isLegalAssistanceProperty(wizardData, wizardData.supportiveValues.value.availableLossCauses)) {
            return false;
        }

        return wizardData.repairDetails.repairOptionChoice.value.code !== 'PreferredVendor'
            && wizardData.supportiveValues.value.reporterContactFoundOnPolicy === true;
    }, [wizardData]);

    const overrides = {
        fnolContactDetailsFirstName: {
            className: firstLastNameStyleDependantOfDevice()
        },
        fnolContactDetailsLastName: {
            className: firstLastNameStyleDependantOfDevice()
        },
        fnolContactDetailsBirthDate: {
            maxDate: moment().toDate(),
            className: halfWidthStyleDependantOfDevice(),
            visible: _.isEmpty(authenticationData)
        },
        fnolContactDetailsEmailAddress: {
            className: firstLastNameStyleDependantOfDevice()
        },
        fnolContactDetailsPhoneNumber: {
            className: firstLastNameStyleDependantOfDevice()
        },
        wizardFNOLContactDetailsLoader: {
            visible: driverIsLoading
        },
        fnolContactDetailsIsInjured: {
            visible: shouldAreYouInjuredBeVisible()
        },
        fnolContactDetailsIsInjuredInvolvement: {
            visible: wizardData.reporterDetails.value.wasInjured === true && isAutoLOB(wizardData.lob.value)
        },
        wizardFNOLContactDetailsFirstLastNameContainer: {
            className: styleIfNoMobile('emailPhoneContainer')
        },
        wizardFNOLContactDetailsEmailPhoneContainer: {
            className: styleIfNoMobile('emailPhoneContainer')
        },
        wizardFNOLContactDetailsOptionalFieldsContainer: {
            visible: wizardData.supportiveValues.value.reporterContactFoundOnPolicy === false
        },
        fnolContactDetailsAddress: {
            onWriteValue: writeValue,
            isCountryMandatory: true,
            isCityMandatory: true,
            isPostalCodeMandatory: true,
            isStreetNameMandatory: true,
            isStreetNumberMandatory: true
        },
        fnolContactDetailsLanguageType: {
            availableValues: availableLanguages,
            value: wizardData.reporterDetails.languageType.value
                ? wizardData.reporterDetails.languageType.value.code
                : null,
            className: halfWidthStyleDependantOfDevice()
        },
        fnolContactDetailsRelationToPolicyOwner: {
            availableValues: availableRelationsToPolicyOwner,
            value: wizardData.reporterDetails.relationToPolicyOwner.value
                ? wizardData.reporterDetails.relationToPolicyOwner.value.code
                : null,
            className: halfWidthStyleDependantOfDevice()
        },
        fnolContactDetailsGenders: {
            value: wizardData.reporterDetails.gender.value
                ? wizardData.reporterDetails.gender.value.code
                : null,
            className: halfWidthStyleDependantOfDevice()
        },
        fnolContactDetailsBankAccountNumber: {
            writeValue: writeValue,
            isBrokerContext: true,
            onValidate: onComponentValidate,
            accountNumberRequired: false,
            visible: shouldShowAccountNumberSection()
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate: onComponentValidate
        },
        resolveComponentMap: {
            address: FNOLAddress,
            bankAccountComponent: BankAccountNumberComponent
        }
    };

    return (
        <ViewModelForm
            model={wizardData}
            uiProps={metadata.componentContent}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            overrideProps={overrides}
            onValidationChange={onComponentValidate}
            onValueChange={writeValue}
        />
    );
}

WizardFNOLContactDetailsComponent.propTypes = wizardProps;
export default withRouter(WizardFNOLContactDetailsComponent);
