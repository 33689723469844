import { defineMessages } from 'react-intl';

export default defineMessages({
    landingPageCarDescription: {
        id: 'anonymous.landingPage.I have a car related claim',
        defaultMessage: 'Car'
    },
    landingPageMotorDescription: {
        id: 'anonymous.landingPage.Motor policy',
        defaultMessage: 'Moto & Moped'
    },
    landingPageHomeDescription: {
        id: 'anonymous.landingPage.I have another type of claim (home, family, …)',
        defaultMessage: 'Home & Family'
    },
    landingPageEBikeDescription: {
        id: 'anonymous.landingPage.EBike policy',
        defaultMessage: 'Bike'
    },
    landingPageMainHeaderDescriptionLineTwo: {
        id: 'anonymous.landingPage.What do you want to report?',
        defaultMessage: 'For what type of policy do you want to report a claim?'
    }
});
