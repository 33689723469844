/* eslint-disable max-len */
import _ from 'lodash';
import React, { useState, useCallback, useEffect, useContext } from 'react';
import { ViewModelForm, ViewModelServiceContext, withViewModelService } from 'gw-portals-viewmodel-react';
import { FNOLService } from 'nn-capability-fnol';
import { useValidation } from 'gw-portals-validation-react';
import { TranslatorContext } from '@jutro/locale';
import { ModalNextProvider } from '@jutro/components';
import SelectablePolicy from '../../components/SelectablePolicy/SelectablePolicy';
import AuthenticatedFlowBikeUrgencyPopup from '../../components/AuthenticatedFlowBikeUrgencyPopup/AuthenticatedFlowBikeUrgencyPopup';
import AuthenticatedContext from '../../contexts/AuthenticatedContext/AuthenticatedContext';
import { getTypecodeTranslation } from '../../utils/TypeCodeTranslationUtil';
import { formatDateToBaseFormat } from '../../utils/DateUtil';
import { getWizardStepBasedOnProgress } from '../../utils/WizardStepToProgressUtil';
import nnCustomMessages from '../../nnCustomMessages';
import { redirectToContactCallCenter, redirectToAuthContactCallCenter } from '../../utils/RedirectionUtil';
import { isBikeLOB } from '../../utils/PolicyTypeUtil';
import PartnerContext from '../../contexts/PartnerContext/PartnerContext';
import PleaseWait from '../../components/PleaseWait/PleaseWait';
import metadata from './AuthenticatedPolicySelect.metadata.json5';
import messages from './AuthenticatedPolicySelect.messages';
import styles from './AuthenticatedPolicySelect.scss';

function AuthenticatedPolicySelect(props) {
    const viewModelService = useContext(ViewModelServiceContext);
    const partnerContext = useContext(PartnerContext);
    const translator = useContext(TranslatorContext);
    const { history } = props;
    const [availablePoliciesData, setAvailablePoliciesData] = useState({});
    const [policySelectDTO, setPolicySelectDTO] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [noPolicyFoundCounter, setNoPolicyFoundCounter] = useState(0);
    const [timeStampVisibleValue, setTimeStampVisibleValue] = useState();
    const [filteredPolicies, setFilteredPolicies] = useState({});

    const {
        authenticationData
    } = useContext(AuthenticatedContext);

    const {
        onValidate,
        isComponentValid
    } = useValidation('AuthenticatedPolicySelect');

    if (!authenticationData.token || authenticationData.error) {
        // Access to this page should be granted only if there is authentication token in URL path.
        redirectToAuthContactCallCenter(history);
    }

    useEffect(() => {
        // Do not call CC if there is no token or policies are already retrieved
        if (authenticationData.error || !authenticationData.token || availablePoliciesData.userPoliciesSummaries) return;

        const dto = viewModelService.create(
            {
                token: authenticationData.token
            },
            'cc',
            'com.inlb.cc.extsys.edge.authenticatedFnol.dto.initialCommunication.UserPoliciesSearchDTO'
        );

        FNOLService.getUserPolicies(dto.value, history, { partnerContext, translator }).then(
            (resultDTO) => {
                if (!resultDTO) return;
                if (!_.isEmpty(resultDTO.error)) {
                    redirectToAuthContactCallCenter(history);
                }
                setAvailablePoliciesData(resultDTO);

                const initialPolicySelectDTO = viewModelService.create(
                    {
                        policyTypedManually: false,
                        reporterDetails: resultDTO.reporterDetails
                    },
                    'cc',
                    'com.inlb.cc.extsys.edge.authenticatedFnol.dto.policySelect.InitializeAuthUserFlowDTO'
                );
                setPolicySelectDTO(initialPolicySelectDTO);
            }
        );
    // We need to call that only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history, partnerContext, translator]);

    const onSelect = useCallback((data) => {
        const updatedDTO = viewModelService.clone(policySelectDTO);
        _.set(updatedDTO.value, 'policyNumber', data.policyNumber);
        _.set(updatedDTO.value, 'claimNumber', undefined);
        _.set(updatedDTO.value, 'policyTypedManually', false);
        _.set(updatedDTO.value, 'lobCode', data.lob);
        setPolicySelectDTO(updatedDTO);
    }, [policySelectDTO, viewModelService]);

    const onIsSelected = useCallback((data) => {
        return policySelectDTO.value.claimNumber === undefined && data === policySelectDTO.value.policyNumber;
    }, [policySelectDTO]);

    const performCall = () => {
        window.location.href = `tel:${partnerContext.partnerPhoneNumber(translator)}`;
    };

    const clickOtherPolicyCheckBox = useCallback((value) => {
        const updatedDTO = viewModelService.clone(policySelectDTO);
        _.set(updatedDTO.value, 'policyNumber', '');
        _.set(updatedDTO.value, 'claimNumber', undefined);
        _.set(updatedDTO.value, 'policyTypedManually', value);
        _.set(updatedDTO.value, 'lobCode', null);
        setPolicySelectDTO(updatedDTO);
    }, [viewModelService, policySelectDTO]);

    useEffect(() => {
        let displayablePolicies;
        if (!policySelectDTO) {
            displayablePolicies = {};
        } else {
            const dateOfLoss = policySelectDTO.value.lossDate;
            if (_.isEmpty(availablePoliciesData.userPoliciesSummaries) || !dateOfLoss) {
                displayablePolicies = {};
            } else {
                displayablePolicies = availablePoliciesData.userPoliciesSummaries.filter((entry) => {
                    const dateOfLossDate = new Date(dateOfLoss.year, dateOfLoss.month, dateOfLoss.day, dateOfLoss.hour || 0, dateOfLoss.minute || 0);
                    const startDate = new Date(entry.startDate.year, entry.startDate.month, entry.startDate.day, entry.startDate.hour, entry.startDate.minute);

                    const endDateObject = entry.endDate;
                    if (_.isEmpty(endDateObject)) {
                        return startDate <= dateOfLossDate;
                    }

                    const endDate = new Date(entry.endDate.year, entry.endDate.month, entry.endDate.day, entry.endDate.hour, entry.endDate.minute);
                    return endDate >= dateOfLossDate && startDate <= dateOfLossDate;
                });
            }
        }
        setFilteredPolicies(displayablePolicies);
    }, [availablePoliciesData.userPoliciesSummaries, policySelectDTO]);

    const onValueChange = useCallback((value, path) => {
        const updatedDTO = viewModelService.clone(policySelectDTO);

        if (path === 'policyNumber' && _.isEmpty(filteredPolicies)) {
            _.set(updatedDTO.value, 'policyTypedManually', true);
        }

        _.set(updatedDTO.value, path, value);
        setPolicySelectDTO(updatedDTO);
    }, [viewModelService, policySelectDTO, filteredPolicies]);

    const showItsmeInformationalPopup = useCallback(
        async () => {
            if (_.isEmpty(policySelectDTO.value.lobCode) || !isBikeLOB(policySelectDTO.lobCode.value)) return true;

            const results = await ModalNextProvider.showModal(
                <AuthenticatedFlowBikeUrgencyPopup history={history} />
            );
            return results;
        }, [history, policySelectDTO]
    );

    const performPolicySearch = useCallback(() => {
        // First show the urgency popup for EBike flow. 
        showItsmeInformationalPopup(
        ).then((result) => {
            // Set Loading
            setIsLoading(true);
            return result;
        }).then(async (result) => {
            // Verify whether there is a draft
            const response = await FNOLService.retrieveDraftClaimInformation(policySelectDTO.value, history, { partnerContext, translator });
            setIsLoading(false);
            return response;
        }).then(async (result) => {
            const selectDTO = policySelectDTO.value;
            // If a draft is not present, start flow from the beginning.
            if (result.claimNumber === undefined) {
                setIsLoading(true);
                return selectDTO;
            }

            // If a draft exists, we ask customer whether he/she wants to start from the beginning or continue. 
            const popupResults = await ModalNextProvider.showConfirm({
                title: translator(messages.authenticatedPolicySelectSaveAndResumeDraftClaimPopupTitle),
                message: translator(messages.authenticatedPolicySelectSaveAndResumeDraftClaimPopupBody, {
                    lossCause: getTypecodeTranslation(translator, result.lossCause, 'LossCause'),
                    lossDate: formatDateToBaseFormat(result.lossDate, false)
                }),
                confirmButtonText: translator(messages.authenticatedPolicySelectSaveAndResumeConfirmButton),
                cancelButtonText: translator(messages.authenticatedPolicySelectSaveAndResumeCancelButton)
            });

            console.log(popupResults);

            // That means customer closed the popup and we shouldn't proceed.
            if (popupResults === 'close') {
                setIsLoading(true);
                return null;
            }

            // Customer decides to start from beginning.
            if (popupResults === 'cancel') {
                setIsLoading(true);
                return selectDTO;
            }

            // Getting here means customer wants to continue registration process based on the draft.
            selectDTO.claimNumber = result.claimNumber;
            setIsLoading(true);
            return selectDTO;
        }).then(async (result) => {
            if (!result) return

            await FNOLService.initializeAuthenticatedUserFlow(result, history, { partnerContext, translator }).then(
                (resultDTO) => {
                    if (!resultDTO) return;
                    if (!_.isEmpty(resultDTO.error)) {
                        const { error } = resultDTO;
                        switch (error.errorCode) {
                            case '210': // 210 - Policy not found
                                if (noPolicyFoundCounter >= 3) {
                                    history.push({
                                        pathname: '/quick-flow',
                                        state: {
                                            redirectPath: '/',
                                            initialData: result,
                                            timeStampVisibleValue: timeStampVisibleValue
                                        }
                                    });
                                } else {
                                    setNoPolicyFoundCounter((currentValue) => currentValue + 1);
                                }
                                break;
                            case '211': // 211 - Provided Policy Number is outside Developed LOB's
                                redirectToContactCallCenter(history,
                                    translator(nnCustomMessages.fnolContactCallCenterFromLandingRedirectionErrorTitle),
                                    translator(nnCustomMessages.fnolContactCallCenterFromLandingRedirectionErrorSubTitle),
                                    false, false);
                                break;
                            case '500':
                            default:
                                redirectToAuthContactCallCenter(history);
                        }
                    } else {
                        let wizardStepToGoTo = '/fnol';
                        if (resultDTO.progress !== undefined) {
                            wizardStepToGoTo += getWizardStepBasedOnProgress(resultDTO.progress);
                        }
                        history.push({
                            pathname: wizardStepToGoTo,
                            state: {
                                redirectPath: '/',
                                fnolDto: resultDTO
                            }
                        });
                    }
                }
            );
        }).finally(() => {
            setIsLoading(false);
        });
    }, [history, noPolicyFoundCounter, partnerContext, policySelectDTO, showItsmeInformationalPopup, timeStampVisibleValue, translator]);

    if (!policySelectDTO) return (<div />);
    const overrideProps = {
        authenticatedPolicySelectPagePleaseWait: {
            value: isLoading
        },
        authenticatedPolicySelectHeader: {
            title: translator(messages.authenticatedPolicySelectHeader)
        },
        authenticatedPolicySelectLossDate: {
            timeStampVisibleValue: timeStampVisibleValue,
            setTimeStampVisibleValue: setTimeStampVisibleValue
        },
        authenticatedPolicySelectPoliciesAvailable: {
            visible: !!policySelectDTO.value.lossDate
                && (timeStampVisibleValue === false || (timeStampVisibleValue === true && policySelectDTO.value.lossDate.hour !== undefined))
                && !_.isEmpty(filteredPolicies)
        },
        authenticatedPolicySelectPoliciesNotAvailable: {
            visible: !!policySelectDTO.value.lossDate
                && (timeStampVisibleValue === false || (timeStampVisibleValue === true && policySelectDTO.value.lossDate.hour !== undefined))
                && _.isEmpty(filteredPolicies)
        },
        authenticatedPolicySelectPoliciesIterator: {
            visible: !_.isEmpty(filteredPolicies),
            data: filteredPolicies
        },
        authenticatedPolicySelectNameLabels: {
            content: `${translator(messages.authenticatedPolicySelectNameLabelOne)} ${availablePoliciesData.reporterDetails.firstName} ${availablePoliciesData.reporterDetails.lastName} ${translator(messages.authenticatedPolicySelectNameLabelTwo)}`
        },
        authenticatedPolicySelectCheckBox: {
            onValueChange: clickOtherPolicyCheckBox
        },
        authenticatedPolicySelectPolicyNumberInput: {
            visible: !!policySelectDTO.value.lossDate
            && (timeStampVisibleValue === false || (timeStampVisibleValue === true && policySelectDTO.value.lossDate.hour !== undefined))
            && (_.isEmpty(filteredPolicies) || policySelectDTO.value.policyTypedManually === true)
        },
        authenticatedPolicySelectErrorTextDIV: {
            visible: noPolicyFoundCounter !== 0
        },
        authenticationPageCallAssistLineText: {
            content: partnerContext.partnerUrgentAssistLabel(translator),
            onClick: () => performCall()
        },
        authenticationMethodSelectItsmeImage: {
            onClick: () => performCall()
        },
        authenticatedPolicySelectNextButton: {
            disabled: !isComponentValid || _.isEmpty(policySelectDTO.value.policyNumber),
            onClick: performPolicySearch
        }
    };

    const resolvers = {
        resolveComponentMap: {
            selectablePolicy: SelectablePolicy,
            pleaseWait: PleaseWait
        },
        resolveCallbackMap: {
            onValidate,
            onSelect,
            onIsSelected,
            onValueChange
        },
        resolveClassNameMap: styles
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policySelectDTO}
                overrideProps={overrideProps}
                onValidationChange={onValidate}
                onValueChange={onValueChange}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </div>
    );
}

export default withViewModelService(AuthenticatedPolicySelect);
