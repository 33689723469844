import { ANONYMOUS_FLOW_CODE } from '../ClientServiceConstants';
import messages from './AgencyPortalValuesProvider.messages';

export default class AgencyPortalValuesProvider {
    getAvailableFlows = () => {
        return [ANONYMOUS_FLOW_CODE];
    }

    getAnonymousFlowMessage = () => {
        return messages.agencyPortalAnonymousFlowMessage;
    }

    getRedirectionDetails = () => {
        return {
            baseUrlForClient: '/',
            state: {}
        };
    }

    isBrokerContext = () => {
        return false;
    }

    getTypefilterNameForReporterRelation = () => {
        return 'AgentFrontEndSelectable'
    }

    saveAndResumeEnabled = () => {
        return false
    }
    
}
