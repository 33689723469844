/* eslint-disable max-len */
import React, { useCallback, useState, useContext } from 'react';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { withViewModelService } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import { ModalNextProvider } from '@jutro/components';
import { FNOLService } from 'nn-capability-fnol';
import { Buffer } from 'buffer';
import { ServiceManager } from '@jutro/services';
import PartnerContext from '../../contexts/PartnerContext/PartnerContext';
import { ANONYMOUS_FLOW_CODE, ITS_ME_FLOW_CODE } from '../../services/client/ClientServiceConstants';
import AuthenticatedFlowInformationalPopup from '../../components/AuthenticatedFlowInformationalPopup/AuthenticatedFlowInformationalPopup';
import PleaseWait from '../../components/PleaseWait/PleaseWait';
import ClientContext from '../../contexts/ClientContext/ClientContext';
import metadata from './AuthenticationLandingPage.metadata.json5';
import styles from './AuthenticationLandingPage.module.scss';
// eslint-disable-next-line no-unused-vars
import messages from './AuthenticationLandingPage.messages';

function AuthenticationLandingPage(props) {
    const { history } = props;
    const clientContext = useContext(ClientContext);
    const translator = useContext(TranslatorContext);
    const partnerContext = useContext(PartnerContext);
    const [isGDPRConfirmed, setisGDPRConfirmed] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const clientAvailableFlows = clientContext.getAvailableFlows();
    const [authMethod, setAuthMethod] = useState(clientAvailableFlows.indexOf('itsme') !== -1 ? 'itsme' : clientAvailableFlows[0]);

    const redirectToPolicySearch = useCallback(() => {
        return history.push({
            pathname: '/anonymous-lob-page',
            state: {
                redirectPath: '/'
            }
        });
    }, [history]);

    const redirectToItsMe = useCallback(() => {
        setIsLoading(true);
        FNOLService.retrieveServiceToken(history, { partnerContext, translator }).then(
            (tokenResponseDTO) => {
                if (!tokenResponseDTO) return;
                let itsMeURL = process.env.REACT_APP_NN_AUTHENTICATION_BASE_URL;
                itsMeURL += '?source=CustomerEngage&scope=profile&scope=phone&scope=email&scope=address';
                itsMeURL += `&lang=${ServiceManager.getService('locale-service').getStoredLanguage().substring(0, 2)}`;
                itsMeURL += `&subscription-key=${process.env.REACT_APP_NN_AUTHENTICATION_SUBSCRIPTION_KEY}`;
                itsMeURL += '&ChannelID=5003';
                itsMeURL += `&access_token=${tokenResponseDTO.accessToken}`;

                const currentUrl = window.location.href;
                const redirecturi = currentUrl.slice(-1) !== '/' ? `${currentUrl}/policy-select` : `${currentUrl}policy-select`;
                const encodedUri = Buffer.from(redirecturi).toString('base64');
                itsMeURL += `&redirecturi=${encodedUri}`;

                window.location.href = itsMeURL;
            }
        );
    }, [history, partnerContext, translator]);

    const showItsmeInformationalPopup = useCallback(
        async () => {
            const results = await ModalNextProvider.showModal(
                <AuthenticatedFlowInformationalPopup />
            );
            return results;
        }, []
    );

    const handleOnNextClick = useCallback(() => {
        switch (authMethod) {
            case ITS_ME_FLOW_CODE:
                showItsmeInformationalPopup().then((result) => {
                    redirectToItsMe();
                });
                break;
            case ANONYMOUS_FLOW_CODE:
                redirectToPolicySearch();
                break;
            default:
                break;
        }
    }, [authMethod, redirectToItsMe, redirectToPolicySearch, showItsmeInformationalPopup]);

    const redirectToPrivacyPolicy = useCallback(() => {
        return history.push({
            pathname: '/privacy-policy',
            state: {
                redirectPath: '/'
            }
        });
    }, [history]);

    const redirectToAssuralia = useCallback(() => {
        return history.push({
            pathname: '/assuralia-anti-fraud',
            state: {
                redirectPath: '/'
            }
        });
    }, [history]);

    const overrideProps = {
        authenticationLandingPagePleaseWait: {
            value: isLoading
        },
        authenticationLandingPageHeader: {
            title: translator(messages.authenticationLandingPageHeader)
        },
        authenticationLandingPageRadioButtonContainerItsme: {
            visible: clientAvailableFlows.length > 1 && clientAvailableFlows.indexOf(ITS_ME_FLOW_CODE) !== -1
        },
        authenticationLandingPageSingleSignValueContainer: {
            visible: clientAvailableFlows.length === 1,
        },
        authenticationLandingPageSingleSignImage: {
            visible: clientAvailableFlows.indexOf(ITS_ME_FLOW_CODE) !== -1
        },
        authenticationLandingPageSingleSignValue: {
            visible: clientAvailableFlows.length === 1,
            content: translator(clientAvailableFlows.indexOf(ITS_ME_FLOW_CODE) !== -1
                ? messages.authenticationPageItsMeOption
                : clientContext.getAnonymousFlowMessage())
        },
        authenticationMethodSelectItsme: {
            value: authMethod,
            onValueChange: setAuthMethod,
            availableValues: [{
                code: ITS_ME_FLOW_CODE,
                name: messages.authenticationPageItsMeOption
            }]
        },
        authenticationLandingPageRadioButtonContainerAnonymous: {
            visible: clientAvailableFlows.length > 1 && clientAvailableFlows.indexOf(ANONYMOUS_FLOW_CODE) !== -1
        },
        authenticationMethodSelectAnonymous: {
            value: authMethod,
            onValueChange: setAuthMethod,
            availableValues: [{
                code: ANONYMOUS_FLOW_CODE,
                name: clientContext.getAnonymousFlowMessage()
            }]
        },
        fnolSearchPolicyGDPRPrivacyPolicyLink: {
            onClick: redirectToPrivacyPolicy
        },
        fnolSearchPolicyGDPRTextAssuraliaAntiFraudLink: {
            onClick: redirectToAssuralia
        },
        authenticationPageNext: {
            disabled: !isGDPRConfirmed,
            onClick: handleOnNextClick
        },
        fnolSearchPolicyGDPRConsent: {
            value: isGDPRConfirmed,
            onValueChange: setisGDPRConfirmed
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            pleaseWait: PleaseWait,
        }
    };

    return renderContentFromMetadata(
        metadata.pageContent,
        overrideProps,
        resolvers
    );
}

export default withViewModelService(AuthenticationLandingPage);
