import { defineMessages } from 'react-intl';

export default defineMessages({
    authFnolContactCallCenterPageTitle: {
        id: 'anonymous.fnol.components.authenticated.contact-call-center.Something went wrong',
        defaultMessage: 'Something went wrong'
    },
    authFnolContactCallCenterPageSubTitle: {
        id: 'anonymous.fnol.components.authenticated.contact-call-center.Please continue without itsme',
        defaultMessage: 'Please continue without itsme'
    },
    authFnolContactCallCenterPageGoAnonymous: {
        id: 'anonymous.fnol.components.authenticated.contact-call-center.Continue without itsme',
        defaultMessage: 'Continue without itsme'
    }
});
