import { useCallback } from 'react';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { withViewModelService } from 'gw-portals-viewmodel-react';
import useBreakpointHandler from '../../hooks/useBreakpointHandler';
import metadata from './AnonymousLOBSelectionPage.metadata.json5';
import styles from './AnonymousLOBSelectionPage.module.scss';
// eslint-disable-next-line no-unused-vars
import messages from './AnonymousLOBSelectionPage.messages';

function AnonymousLOBSelectionPage(props) {
    const { history } = props;

    const redirectToAutoFlow = useCallback(() => {
        return history.push({
            pathname: '/policy-search',
            state: {
                redirectPath: '/'
            }
        });
    }, [history]);

    const [
        isMobileDevice
    ] = useBreakpointHandler();

    const overrideProps = {
        landingPageCarDescription: {
            onClick: () => redirectToAutoFlow()
        },
        landingPageCarIcon: {
            onClick: () => redirectToAutoFlow(),
            className: isMobileDevice()
                ? 'phoneLandingPageIcon'
                : 'landingPageIcon'
        },
        landingPageHomeIcon: {
            onClick: () => redirectToAutoFlow(),
            className: isMobileDevice()
                ? 'phoneLandingPageIcon'
                : 'landingPageIcon'
        },
        landingPageEbikeIcon: {
            onClick: () => redirectToAutoFlow(),
            className: isMobileDevice()
                ? 'phoneLandingPageIcon'
                : 'landingPageIcon'
        },
        landingPageHomeDescription: {
            onClick: () => redirectToAutoFlow()
        },
        landingPageMotor: {
            onClick: () => redirectToAutoFlow()
        },
        landingPageMotorDescription: {
            onClick: () => redirectToAutoFlow()
        },
        landingPageEBikeDescription: {
            onClick: () => redirectToAutoFlow()
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return renderContentFromMetadata(
        metadata.pageContent,
        overrideProps,
        resolvers
    );
}

export default withViewModelService(AnonymousLOBSelectionPage);
